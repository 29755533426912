.page-hero {
  background-color: $blue-grey-dark;
  background-image: url("/img/desk_hero.png");
  background-position: 50% 70%;
  background-size: cover;
  height: 220px;
  position: relative;

  @include breakpoint(large up) {
    background-position: 50% 40%;
    height: 250px;
  }

  &__copy {
    color: $white;
    padding: 2rem 1rem 0;
    position: relative;
    z-index: 2;

    @include breakpoint(large up) {
      padding-top: 5rem;
      text-align: center;
    }

    h1 {
      font-size: rem-calc(25);

      @include breakpoint(large up) {
        font-size: rem-calc(40);
      }
    }

    p {
      color: $text-weak-color;
      line-height: 1.8rem;

      @include breakpoint(large up) {
        font-size: rem-calc(20);
        margin: 0 auto;
        width: 620px;
      }
    }
  }

  &__overlay {
    background-color: $blue-grey-dark;
    bottom: 0;
    left: 0;
    opacity: .7;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
