@import "../components/calculadora";

//
// ONLY DETAIL PAGE
//

.detail-section {
  padding: rem-calc(70) 1rem;

  h2 {
    color: $blue-grey-dark;
    font-size: rem-calc(18);
    margin-bottom: rem-calc(30);

    @include breakpoint(large up) {
      font-size: rem-calc(24);
    }
  }

  &.detail-360 {
    iframe {
      border-radius: 3px;
      transition: all 0.2s;
      width: 100%;
      margin-bottom: 20px;
      border: 1px solid #eee;
    }

    a {
      font-size: 0.8rem;
    }
  }

  .panorama_cta {
    position: relative;
    display: inline-block;
    transition: all 0.2s;

    @include breakpoint(large) {
      &:hover {
        transform: scale(1.05);
      }
    }

    .panorama_overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
      background: rgba(0, 0, 0, 0.34);
      background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0.34) 0%,
        rgba(15, 15, 15, 0.49) 23%,
        rgba(15, 15, 15, 1) 100%
      );
      background: -webkit-gradient(
        left top,
        left bottom,
        color-stop(0%, rgba(0, 0, 0, 0.34)),
        color-stop(23%, rgba(15, 15, 15, 0.49)),
        color-stop(100%, rgba(15, 15, 15, 1))
      );
      background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.34) 0%,
        rgba(15, 15, 15, 0.49) 23%,
        rgba(15, 15, 15, 1) 100%
      );
      background: -o-linear-gradient(
        top,
        rgba(0, 0, 0, 0.34) 0%,
        rgba(15, 15, 15, 0.49) 23%,
        rgba(15, 15, 15, 1) 100%
      );
      background: -ms-linear-gradient(
        top,
        rgba(0, 0, 0, 0.34) 0%,
        rgba(15, 15, 15, 0.49) 23%,
        rgba(15, 15, 15, 1) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.34) 0%,
        rgba(15, 15, 15, 0.49) 23%,
        rgba(15, 15, 15, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#0f0f0f', GradientType=0 );

      text-align: center;
      color: white;
      display: grid;
      // align-items: center;
      grid-template-rows: 83px 23px;
      text-shadow: 1px 1px 1px #000;
      transition: all 0.2s;
      padding-top: 5%;

      @include breakpoint("medium") {
        padding-top: 20%;
      }

      i {
        align-self: end;
        display: inline-block;
        font-size: 40px;

        @include breakpoint("medium") {
          font-size: 55px;
        }
      }

      span {
        align-self: start;
        display: inline-block;
        font-size: 14px;

        @include breakpoint("medium") {
          font-size: 22px;
        }
      }
    }
  }
}

.detail-info {
  border-top: 1px dotted #eee;
  padding: 20px 1rem;
  @include breakpoint(large) {
    padding: 20px 20px;
  }

  .detail-info-content {
    position: relative;

    .qrcode{
      display: none;

      @include breakpoint(768){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 150px;
      }

      .code{
        width: 100px;
        height: 100px;
        margin-left: 25px;
      }
      .tagline{
        color: $secondary-color;
        font-size: rem-calc(13);
        text-align: center;
        display: inline-block;
        width: 100%;
        padding-top: 5px;
      }
    }

    .title {
      position: relative;

      img.logo-marca{
        border: 1px solid #eee;
        margin-bottom: 20px;
        width: 100%;

        @include breakpoint(768){
          max-width: 375px;
          position: absolute;
          right: 0;
        }
      }

      h1 {
        font-size: 1.5rem;
        line-height: 1.6rem;
        color: #444;
        @include breakpoint(medium) {
          font-size: 2rem;
          line-height: 2.2rem;
        }
      }

      .year {
        border: 1px solid #e8ecee;
        border-radius: 2px;
        font-size: 0.875rem;
        font-weight: bold;
        text-align: center;
        top: 0;
        width: 55px;
        display: inline-block;
      }
    }

    .description {
      margin-top: 15px;
    }

    .price {
      font-size: 1.5rem;
      color: $secondary-color;
      margin-top: 20px;

      @include breakpoint(medium) {
        font-size: 2rem;
      }

      &.oferta{
        .precio_publicacion{
          color: rgb(241, 70, 70);
          text-decoration: line-through;
          font-size: rem-calc(14px);
          display: inline-block;
          width: 100%;
        }
      }

      &.no-oferta{
        .precio_promocional{
          display: none;
        }
      }
    }

    .tools {
      margin-top: 20px;
      a {
        margin-right: 20px;
      }
    }
  }
}

.detail-row-description {
  @extend %u-normalize-list;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  max-width: 1000px;

  @include breakpoint(large up) {
    grid-template-columns: repeat(2, 1fr);
  }

  li {
    font-size: rem-calc(12);
    margin-bottom: 1rem;

    @include breakpoint(large up) {
      font-size: 1rem;
    }
  }
}

.detail-vehicle {
  background-color: $pale-grey-two;

  &__certificate {
    background-color: $white;
    border: 1px solid $pale-grey-three;
    display: flex;
    margin: 2.5rem auto 0;
    max-width: 550px;
    padding: rem-calc(15);

    @include breakpoint(large up) {
      max-width: 620px;
    }

    i {
      color: $secondary-color;
      font-size: rem-calc(48);
      line-height: 3.5rem;
      margin-right: rem-calc(10);
    }

    h3 {
      color: $secondary-color;
      font-size: rem-calc(18);
      margin-bottom: rem-calc(4);
    }

    p {
      font-size: rem-calc(12);
      margin-bottom: 0;

      @include breakpoint(large up) {
        font-size: rem-calc(14);
      }
    }
  }

  .centered{
    text-align: center;
    width: 100%;
    margin-bottom: 40px;
  }

  table {
    border: 1px solid #eee;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
    margin-bottom: 20px;
    background-color: white;
    td {
      border: 1px solid #eee;
      font-size: 0.8rem;
      padding: 4px;

      &:first-child{
        font-weight: bold;
      }
    }
  }
}

.detail-gallery {
  background-color: $pale-grey-two;
  padding: 0 1rem;

  @include breakpoint(xlarge up) {
    padding: 0;
  }

  h2 {
    color: $blue-grey-dark;
    font-size: rem-calc(18);
    margin-bottom: 0;
    padding-bottom: rem-calc(70);

    @include breakpoint(large up) {
      font-size: rem-calc(24);
      padding-bottom: rem-calc(40);
    }
  }
}

.fullscreen-gallery {
  display: none;
  position: fixed;
  z-index: 101;
  background-color: rgba(34, 34, 34, 0.9);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.active {
    display: grid;
  }

  .rotationMessage {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;

    svg {
      path {
        fill: $white;
      }
    }

    p {
      margin: 1.5rem 1rem 0;
      text-align: center;
    }

    i.mdi-close {
      cursor: pointer;
      font-size: 1.25rem;
      position: absolute;
      top: 1.5rem;
      left: 1.25rem;
    }
  }

  .galleryContainer {
    display: none;
    min-height: 100px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(34, 34, 34, 0.9);

    // @include breakpoint(1024) {
    // 	width: 100%;
    // 	margin-left: 0;
    // 	padding-top: 0;
    // }

    .salirFS {
      position: absolute;
      top: 10px;
      left: 10px;
      font-size: 12px;
      background-color: rgba(42, 42, 42, 0.9);
      z-index: 99;
      padding: 10px;
      color: white;
      border-radius: 3px;
      transition: all 0.2s;
      box-shadow: 1px 0px 40px -10px rgba(0, 0, 0, 0.62);
      opacity: 0.8;

      &:hover {
        box-shadow: 1px 0px 40px -10px rgba(0, 0, 0, 1);
      }

      @include breakpoint(1024) {
        font-size: 1rem;
        padding: 15px 20px;
        top: 10px;
      }
    }

    .siemaArrow {
      position: absolute;
      z-index: 99;
      top: 35%;
      display: inline-block;
      font-size: 5rem;
      color: #fff;
      text-shadow: 2px 2px 10px #333;

      i {
        text-shadow: 2px 2px 10px #333;
      }

      &.siemaNext {
        right: 0;
      }
    }
  }

  @include breakpoint (landscape) {
    .rotationMessage {
      display: none;
    }

    .galleryContainer {
      display: block;
    }
  }
}

.vehicle-gallery {
  background-color: $pale-grey-two;
  overflow-y: visible !important;
  width: 100vw;
  position: relative;

  .ver_mas_fotos {
    display: none;
    position: absolute;
    bottom: 40px;
    left: 27px;
    background-color: white;
    z-index: 99;
    padding: 15px 20px;
    color: $secondary-color;
    border-radius: 3px;
    transition: all 0.2s;
    box-shadow: 1px 0px 40px -10px rgba(0, 0, 0, 0.62);

    @include breakpoint(medium) {
      display: block;
    }

    &:hover {
      box-shadow: 1px 0px 40px -10px rgba(0, 0, 0, 1);
    }
  }

  &__image {
    img {
      // height: 200px;
      transition: all 0.2s;

      @include breakpoint(medium) {
        // height: 370px;
      }

      @include breakpoint(large) {
        // height: 427px;
        cursor: pointer;
        &:hover {
          // transform: scale(1.1);
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }

  i {
    color: white;
    font-size: rem-calc(80);
    line-height: rem-calc(50);
    text-shadow: 2px 2px 10px #333;
  }
}

.detail-features {
  background-color: $pale-grey-two;
}

.detail-peace-of-mind {
  &__list {
    display: grid;
    grid-gap: 2rem;

    @include breakpoint(large up) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

.detail-peace {
  display: flex;

  &__icon {
    flex-shrink: 0;
    height: 65px;
    text-align: center;
    width: 65px;

    i {
      color: $pale-grey;
      font-size: rem-calc(35);
    }
  }

  &__description {
    margin-left: 1rem;
    padding-top: 8px;

    h4 {
      font-size: 1rem;
      font-weight: bold;
      margin-bottom: 0;
    }

    a {
      color: $secondary-color;
    }

    p {
      color: $text-weak-color;
      font-size: rem-calc(14);
      margin-bottom: 0;
    }
  }
}

.close-button {
  float: right;
  margin-bottom: 10px;
  span {
    font-size: 2rem;
  }
}

.detail-sticky-menu {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 -3px 20px $pale-grey-three;
  display: none;
  left: 0;
  padding: 1.4rem 0;
  position: fixed;
  right: 0;
  z-index: 100;

  @include breakpoint(large up) {
    bottom: auto;
    box-shadow: 0 2px 8px $pale-grey-three;
    padding: 1rem;
    top: 0;
  }

  @include breakpoint(xlarge up) {
    padding: 1rem 2.5rem;
  }

  &__info {
    display: none;

    @include breakpoint(large) {
      display: block;
      float: left;
      width: calc(100% - 355px);
    }
  }

  &__info-detail {
    position: relative;
  }

  &__info-year {
    border: 1px solid $pale-grey-three;
    border-radius: 2px;
    font-size: rem-calc(14);
    font-weight: bold;
    text-align: center;
    top: 0;
    width: 55px;

    @include breakpoint(xlarge up) {
      font-size: 1rem;
      right: -4rem;
    }
  }

  &__info-title {
    font-size: rem-calc(18) !important;
    line-height: 1.7rem;
    margin-bottom: 0;
    color: #62656f;
    font-weight: bold;

    @include breakpoint(large) {
      font-size: rem-calc(28);
    }
  }

  &__info-description {
    color: $text-weak-color;
    font-size: rem-calc(14);
    margin-bottom: 0;
    color: #62656f;

    @include breakpoint(large) {
      font-size: rem-calc(18);
    }
  }

  &__info-price {
    color: $secondary-color;
    font-size: rem-calc(18);
    display: inline-block;

    @include breakpoint(xlarge) {
      font-size: rem-calc(28);
      position: absolute;
      right: 440px;
      top: 30px;
    }
  }

  &__actions {
    text-align: center;
    width: 100%;

    @include breakpoint(large) {
      width: 355px;
      float: right;
    }

    @include breakpoint(768) {
      margin-top: 25px;
    }

    @include breakpoint(950) {
      margin-top: 13px;
    }

    a {
      margin-bottom: 0;
    }

    a:first-child {
      &:hover,
      &:active {
        // color: white;
      }
    }
  }
}

.detail-sticky-menu--active {
  display: block;
}

.modalHero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url("/img/desk_hero.png");
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-position: center center;
  padding: 40px 20px;

  .heading {
    h4 {
      color: $white;
      font-size: 1.5rem;
      font-weight: 500;
      margin: 0;
    }
  }
}

.modalButtons {
  display: flex;
  flex-direction: column;

  @include breakpoint(large up) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .button {
    @include breakpoint(large up) {
      order: 2;
      margin-bottom: 0;
    }
  }

  .link {
    margin-top: 1rem;
    color: $cool-blue;
    font-size: 14px;

    @include breakpoint(large up) {
      margin-top: 0;
      margin-right: 1.75rem;
      order: 1;
    }
  }
}

.close-button {
  cursor: pointer;
  color: $white;
  position: absolute;
  top: 10px;
  right: 20px;
}

#loginRequired {
  padding-top: 200px;

  div.login_request {
    padding: 25px 8px 10px;

    .modalButtons {
      @include breakpoint(large up) {
        margin-top: 1.75rem;

        a.button {
          margin-top: 0;
        }
      }
    }
  }
}

#actionsModal {
  text-align: center;
  position: relative;
  padding-top: 200px;

  @include breakpoint(1024){
    width: 800px;
  }

  p.legend {
    color: $cool-grey;
    margin: 1.5rem 0;
  }

  p.lead {
    margin-top: 20px;
    color: $secondary-color;
  }

  .actionSelector {
    display: none;

    &.active {
      display: block;
    }

    ul {
      list-style: none;
      margin: 0 -1rem;

      li {
        width: 100%;
        text-align: left;
        border-top: 1px solid #eee;

        a {
          color: $cool-blue;
          display: flex;
          padding: 1rem 1.75rem;
          align-items: center;
          justify-content: space-between;

          i {
            color: $pale-grey;
          }

          &:hover {
            i {
              color: $cool-blue;
            }
          }
        }
      }
    }
  }

  .informacionElements {
    display: none;

    &.active {
      display: block;
    }

    .informacionForm {
      display: none;

      &.active {
        display: block;
      }
    }

    .informacionResult {
      display: none;
      &.active {
        display: block;
      }
    }

    .informacionError {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .testDriveElements {
    display: none;

    &.active {
      display: block;
    }

    .testDriveForm {
      display: none;

      &.active {
        display: block;
      }
    }

    .testDriveResult {
      display: none;
      &.active {
        display: block;
      }
    }

    .testDriveError {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .separarElements {
    display: none;
    &.active {
      display: block;
    }

    .separarForm {
      display: none;
      margin-top: 2rem;
      padding: 0 1rem 1rem;

      &.active {
        display: block;
      }
    }

    .separarError {
      display: none;
      &.active {
        display: block;
      }
    }

    .separarResult {
      display: none;
      &.active {
        display: block;
        .botones{
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 20px;
          max-width: 80%;
          margin: 20px auto;
        }
      }
    }
  }

  .creditoElements {
    display: none;
    &.active {
      display: block;
    }

    .creditoForm {
      display: none;
      &.active {
        display: block;
      }
    }

    .creditoError {
      display: none;
      &.active {
        display: block;
      }
    }

    .creditoResult {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .formularioActions {
    padding: 15px;
    opacity: 0;
    transition: all 0.2s;

    &.active {
      opacity: 1;
    }

    .section_title{
      color: #1f90c5;
      text-align: left;
      display: inline-block;
      width: 100%;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: rem-calc(14);
    }

    label {
      text-align: left;
      margin-bottom: 10px;
      text-transform: uppercase;

      input,
      select {
        background-color: #f5f8f9;
        margin-bottom: 5px;
        padding: 25px 10px;
      }

      select{
        background-size: 9px 6px;
        background-origin: content-box;
        background-position: right 10px center;
        background-repeat: no-repeat;
        padding: 12px 10px;
        height: auto;
      }

      i.error {
        color: red;
        opacity: 0;
        transition: all 0.2s;
      }

      &.error {
        i.error {
          opacity: 1;
          transition: all 0.2s;
        }
      }
    }
  }

  .modalButtons {
    @include breakpoint(large up) {
      justify-content: space-between;
    }
  }

  label.autorizacion{
    position: relative;
    padding-left: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;

    input[type="checkbox"]{
      left: 0;
      position: absolute;
      top: 5px;
    }

    &.error{
      color: red;
    }
  }
}

.tabla_peritaje {
  border: 1px solid #fff;
  width: 100%;
  tr {
    border-bottom: 1px solid #fff;
    td {
      padding: 10px;

      &:last-child {
        width: 150px;
        font-weight: bold;
      }
    }

    &:nth-child(odd) {
      background-color: #e8ecee;
    }
    &:nth-child(even) {
      background-color: #fff;
    }
  }
}

#peritaje_modal {
  h2 {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }
}


.info_block{
  margin-bottom: 40px;

  &.miniform{
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .row{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    &.double{
      @include breakpoint(768){
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
      }
    }
  }

  table{
    td{
      font-size: rem-calc(14);
      padding: 3px;
      &.center{
        text-align: center;
      }

      &:first-child{
        width: auto;
        font-weight: bold;
        text-transform: none;
      }
    }
    thead{
      td{
        font-weight: bold;
      }
    }
  }

  p.resultado_declaracion{
    margin-top: 20px;
    font-weight: bold;
  }

  &.certificado{
    thead{
      tr{
        td{
          background-color: #eee;
        }
      }
    }

    table{
      td{
        vertical-align: top;
      }
      tbody{
        td{
          &:first-child{
            font-weight: normal;
          }

          &.center{
            text-align: center;
          }
        }
      }

    }
  }
}

#certificado_modal {
  h3, h4{
    color: $cool-blue;
  }
}
