header.header{
  z-index: 99;

  @include breakpoint(921){
    padding-top: 30px;
  }
}

.xibilla-menu{
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;

  @include breakpoint(921){
    text-align: right;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    text-align: center;
  }

  li{
    display: inline-block;
    width: 100%;

    @include breakpoint(921){
      margin-left: 5px;
      width: auto;
    }

    @include breakpoint(1024){
      margin-left: 20px;
    }

    &:first-child{
      margin-left: 0;
    }

    a{
      color: #62656f;
      text-transform: uppercase;
      font-weight: bold;
      font-size: rem-calc(12px);
      transition: all .2s;
      padding: 10px 15px;
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #eee;

      @include breakpoint(921){
        padding: 0;
        border-bottom: none;
      }

      &:hover{
        color: #1d71bb;
      }

      &.active{
        color: #1d71bb;
      }
    }

    &.usermenu{
      @include breakpoint(921){
        position: relative;
      }

      a{
        display: none;

        @include breakpoint(921){
          display: inline-block;
        }
      }

      ol{
        display: inline-block;
        margin: 0;
        padding: 0;
        width: 100%;

        @include breakpoint(921){
          display: none;

          &.enabled{
            display: block;
            position: absolute;
            top: 30px;
            right: 0;
            width: 180px;
            background-color: white;
            box-shadow: 0px 13px 20px 1px #44444478;
            padding: 10px 10px;
            border: 1px solid #eee;
            border-radius: 3px;
            border-top: 3px solid #1d71bb;

            &:after{
              border: 10px solid transparent;
              border-top-color: #1d71bb;
              content: "";
              height: 0;
              position: absolute;
              right: 20px;
              top: -1.2rem;
              transform: rotate(180deg);
              width: 0;
              z-index: -1;
            }

            li{
              width: 100%;
              margin-bottom: 10px;
              text-align: left;
              margin-left: 0;

              &:last-child{
                margin-bottom: 0;
              }
            }
          }
        }

        li{
          a{
            display: block;
          }
        }
      }
    }
  }
}
