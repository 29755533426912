
// COMPONENTE: LIGTHBOX DE CONTACTO
#market_modal {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: "Montserrat", sans-serif;

  .close-button{
    color: #666;

  }

  @include breakpoint(large) {
    margin-bottom: 80px;
  }

  .market-modal,
  .market-modal-tks,
  .market-modal-form {
    display: none;

    &.active {
      display: block;
    }

    p.car-title {
      font-weight: 600;
      font-size: 0.9rem;
      display: block;
      height: 40px;
      max-width: 500px;
      margin: 0 auto;
      text-align: center;

      span {
        display: inline-block;
        border: 1px solid #eee;
        border-radius: 2px;
        padding: 2px 4px;
        font-size: 0.8rem;
        font-weight: 400;
      }
    }

    h2 {
      color: $secondary-color;
      font-size: 1.6rem;
      line-height: 1.8rem;
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 10px;
      text-align: center;
      font-family: "Montserrat", sans-serif;
    }

    p.disclaimer {
      color: #555;
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 20px;
      &.form {
        text-align: center;
        font-size: 1rem;
      }
    }

    table {
      border: 1px solid #eee;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 20px;
      td {
        border: 1px solid #eee;
        font-size: 0.8rem;
        padding: 4px;
      }
    }

    #form-market-next {
      margin-left: calc(50% - 120px);
    }

    #form-market-continue {
      margin-left: calc(50% - 65px);
    }

    .contact-form {
      max-width: 500px;

      label {
        input {
          margin-bottom: 5px;
        }

        i.error {
          color: red;
          margin-bottom: 10px;
          display: inline-block;
          width: 100%;
          transition: all 0.2s;
          opacity: 0;
        }

        &.error {
          input {
            border-bottom: 1px solid red;
            transition: all 0.2s;
          }

          i.error {
            opacity: 1;
          }
        }
      }
    }
  }

  .market-modal-tks {
    display: none;
    text-align: center;
    &.active {
      display: block;
    }
  }
}
