// Reference:
// http://www.google.com/design/spec/components/switches.html

//@include foundation-switch;

// Radio and checkbox inputs
.switch-container .checkbox-radio, .checkbox-container {
  input[type="checkbox"], input[type="radio"] {
    display: none;
    cursor: pointer;
    transition: all .4s ease-in-out;
  }
}

.checkbox-container, .checkbox-radio {
  margin-top: rem-calc(4);
  margin-bottom: 1rem;
  position: relative;
  input[type="checkbox"] + label, input[type="radio"] + label {
    vertical-align: top;
    margin-top: 0px;
    line-height: 16px;
  }
  label {
    padding-left: 25px;
    margin-left: 0;
    &:before {
      content: "";
      display: inline-block;
      height: 16px;
      width: 16px;
      position: absolute;
      left: 0;
      top: -1px;
      transition: all .2s ease-in-out;
    }
  }
}

.checkbox-container {
  label {
    &:before {
      border: 2px solid $medium-gray;
      border-radius: $global-radius;
      background-color: rgba($secondary-color, 0);
    }
  }
  input[type="checkbox"]:checked + label:before {
    line-height: rem-cal(18);
    font-size: 1rem;
    color: $grey-200;
    background-color: $secondary-color;
    border: 0;
    padding-left: rem-cal(2);
    font-weight: bold;
    display: inline-block;
    font-family: "Material Design Icons";
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\F12C';
  }
  input[type="checkbox"]:disabled {
    & + label {
      cursor: not-allowed;
      &:before {
        border: 2px solid transparent;
        background-color: rgba(#000, 0.26);
      }
    }
    &:checked + label {
      &:before {
        font-family: "Material Design Icons";
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\F12C';
        line-height: rem-cal(18);
        font-size: 1rem;
        color: #fff;
        background-color: rgba(#000, 0.26);
        border: 0;
        padding-left: rem-cal(2);
      }
    }
  }
}

.checkbox-radio {
  label {
    &:before {
      width: 0px;
      height: 0px;
      border: 0px solid $secondary-color;
      position: absolute;
      top: 9px;
      left: 9px;
      border-radius: 10px;
    }
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      left: -1px;
      top: -1px;
      border: 2px solid $medium-gray;
      border-radius: 10px;
    }
  }
  input[type="radio"]:checked + label {
    &:before {
      width: 6px;
      height: 6px;
      border: 5px solid $secondary-color;
      position: absolute;
      top: 4px;
      left: 4px;
    }
    &:after {
      border-color: $secondary-color;
    }
  }
  input[type="radio"]:disabled + label {
    &:after {
      border-color: rgba(#000, 0.26);
    }
  }
  input[type="radio"][checked] {
    &:disabled + label {
      cursor: not-allowed;
      &:before {
        width: 6px;
        height: 6px;
        border: 5px solid rgba(#000, 0.26);
        position: absolute;
        top: 4px;
        left: 4px;
      }
    }
  }
}

// Switches
.md-switch {
  margin-bottom: 1rem;
  label {
    margin-top: 0.25rem;
    background: rgba(0, 0, 0, 0.26);
    &:after {
      background: #fafafa;
      content: "";
      display: block;
      height: 1.625rem;
      left: 0.25rem;
      position: relative;
      top: 0.25rem;
      transform: translate3d(0px, 0px, 0px);
      transition: left 0.15s ease-out 0s;
      width: 1.625rem;
    }
  }

  input + label {
    height: rem-calc(14);
    border-radius: rem-calc(7);
    width: 2rem;
    box-shadow: 0 1px 1px $black-26 inset;
    cursor: pointer;
    margin-right: 0;
  }

  input + label:after {
    @extend .z-depth-1-hard;
    border-radius: rem-calc(10);
    top: rem-calc(-3);
    left: rem-calc(-5);
    height: 1.25rem;
    width: 1.25rem;
  }

  input[type="checkbox"]:checked + label {
    background: rgba($secondary-color, 0.5);
    &:after {
      background: $secondary-color;
      left: 1.1rem;
    }
  }

  input[type="checkbox"]:disabled + label {
    cursor: not-allowed;
    background: $grey-300;
    &:after {
      @extend .z-depth-1-light;
      background: $grey-400;
    }
  }

  span {
    @include form-label;
    margin-top: 0.875rem;
  }
}