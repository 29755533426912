$xibilla-blue: #0f80c1;

.ck-quick-filters{
  padding: 15px 15px 10px 15px;
  width: 100%;
  display: none;

  &.active{
    display: block;
  }

  .this_quick_filter {
    display: inline-grid;
    font-size: 0.8rem;
    background-color: white;
    border: 1px solid #eee;
    padding: 5px 10px;
    color: #333;
    grid-template-columns: auto 20px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 2px;
    text-transform: capitalize;

    a {
      color: #333;
      display: inline-block;
      justify-self: end;
    }

    &:hover {
      border: 1px solid #aaa;
      transition: all 0.2s;

      a {
        color: $xibilla-blue;
        transition: all 0.2s;
      }
    }
  }

  .remove_all_filters {
    font-size: 0.8rem;
    display: inline-block;
    a {
      color: $xibilla-blue;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
