.terms {
  background: $pale-grey-two;
  min-height: calc(100vh - 140px);
  padding-bottom: 4rem;
  padding-top: 4rem;

  &__title {
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: center;
    width: 94%;

    @include breakpoint(large up) {
      width: 70%;
    }
  }

  &__content {
    margin: 0 auto;
    width: 94%;

    @include breakpoint(large up) {
      width: 70%;
    }

    p {
      line-height: 1.3rem;
    }
  }

  &__content-subtitle {
    color: $secondary-color;
    margin-bottom: 14px;
  }

  &__content-text {
    font-size: 12px;
  }
}
