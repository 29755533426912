.signup {
	&__error-message {
		color: $red;
		font-size: .9rem;
		margin-bottom: 1.5rem;
		text-align: center;

		.contact-form__terms > .is-invalid-label {
			a {
				color: #cc4b37 !important;
			}
		}
	}
}
