.seller-steps-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 3;
}

.seller-steps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include breakpoint(1000) {
    justify-items: start;
    max-width: 480px;
    padding: 0;
  }
}

.seller-step {
  color: $text-weak-color;
  display: flex;
  font-size: rem-calc(12);
  justify-content: center;
  padding: 1rem 0;
  width: 100%;

  @include breakpoint(1000) {
    justify-content: flex-start;
    width: 85%;
  }

  span {
    font-size: rem-calc(24);
    line-height: 1.7rem;
    margin-right: rem-calc(5);

    @include breakpoint(1000) {
      margin-right: rem-calc(10);
    }
  }

  p {
    display: none;
    line-height: 1rem;
    margin-bottom: 0;
    width: 80px;

    @include breakpoint(1000) {
      display: block;
      width: 100px;
    }
  }
}

.seller-step--active {
  border-top: 3px solid $white;
  color: $white;
  padding-top: calc(1rem - 2px);

  p {
    display: block;
  }
}
