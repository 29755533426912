label {
  color: $text-weak-color;
  font-size: rem-calc(12);
}

[type="text"],
[type="password"],
[type="number"],
[type="date"],
[type="email"],
select,
textarea {
  border: 0;
  border-bottom: 1px solid $pale-grey-three;
  border-radius: 0;
  box-shadow: none;
  color: inherit;
  font-size: rem-calc(18);
  height: 1.9rem;
  padding: 0;

  &:focus {
    border: 0;
    border-bottom: 1px solid $secondary-color;
    box-shadow: none;
  }
}

.select-wrapper {
  cursor: pointer;
  position: relative;

  select {
    cursor: inherit;
  }
}

.select-wrapper::after {
  border-style: solid;
  border-width: 0.1em 0.1em 0 0;
  content: "";
  display: inline-block;
  height: 0.5em;
  position: absolute;
  right: 0.4rem;
  top: 0.4rem;
  transform: rotate(135deg);
  vertical-align: top;
  width: 0.5em;
}

[type="checkbox"] {
  margin: 0;
}

.checkbox {
  align-items: center;
  display: flex;
}

textarea {
  resize: none;
}

.is-invalid-input:not(:focus) {
  background-color: transparent;
  border-color: $red;
}
