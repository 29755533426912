.seller-detail-hero {
  background-color: $blue-grey-dark;
  color: $white;
  padding: 1rem rem-calc(10);

  &__container {
    @include breakpoint(large up) {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1rem;
    }

    @include breakpoint(1000) {
      max-width: 90%;
    }
  }
}

.seller-detail-car {
  @include breakpoint(large up) {
    align-items: center;
    display: flex;
  }

  &__info {
    @include breakpoint(1000) {
      margin-left: 1rem;
    }
  }

  &__image {
    display: none;

    @include breakpoint(1000) {
      display: block;
    }

    img {
      height: 125px;
      width: 222px;
    }
  }

  &__ref {
    align-items: center;
    display: flex;
    font-size: rem-calc(24);

    span {
      border: 1px solid $pale-grey-three;
      border-radius: 1.8px;
      font-size: rem-calc(14);
      font-weight: bold;
      margin-left: rem-calc(10);
      padding: 0 .7rem;
    }
  }

  &__description {
    color: $text-weak-color;
    font-size: rem-calc(14);
  }

  &__price {
    font-size: rem-calc(18);
  }
}

.seller-detail-info {
  align-items: center;
  display: flex;
  margin: 1rem 0 .7rem;

  &__visits,
  &__state {
    @include breakpoint(large up) {
      border-left: 1px solid $cool-grey;
      display: flex;
      flex-direction: column;
      font-size: rem-calc(14);
      font-weight: bold;
      height: 60px;
      padding-left: 2.2rem;
    }
  }

  &__visits {
    @include breakpoint(large up) {
      margin-right: 1rem;

      >span:first-child {
        font-size: rem-calc(28);
        font-weight: 400;
        order: 2;
      }
    }
  }

  &__state {
    margin-left: 1.4rem;

    @include breakpoint(large up) {
      >div:first-child {
        margin-bottom: .7rem;
      }
    }
  }
}

.seller-detail-steps {
  background-color: $pale-grey-two;
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  @include breakpoint(1000) {
    grid-template-columns: 2fr repeat(3, 1fr) 2fr;
  }
}

.seller-detail-step {
  border-left: 1px solid $pale-grey-three;
  color: $text-weak-color;
  padding: 1.5rem 0 0 rem-calc(10);

  @include breakpoint(large up) {
    padding-left: 1.8rem;
  }

  &:first-child {
    border-left: 0;

    @include breakpoint(large up) {
      display: flex;
      justify-content: flex-end;
      padding-right: 1.5rem;
    }

  }
}

.seller-detail-step-container {
  @include breakpoint(1000) {
    width: 140px;
  }

  span {
    font-size: rem-calc(24);
    font-weight: bold;

    @include breakpoint(large up) {
      font-size: rem-calc(42);
    }

    i {
      font-size: inherit;
    }
  }

  h4,
  p {
    display: none;

    @include breakpoint(large up) {
      display: block;
    }
  }

  h4 {
    font-size: rem-calc(18);
    font-weight: bold;
    margin-bottom: .2rem;
  }

  p {
    font-size: rem-calc(12);
  }
}

.seller-detail-step--active {
  border-top: 5px solid $secondary-color;

  span {
    color: $secondary-color;

    @include breakpoint(medium down) {
      line-height: 1.8rem;
    }
  }
}

.seller-detail-current-step {
  grid-column: 1 / -1;
  margin-top: 1rem;
  padding-left: rem-calc(10);

  h4 {
    color: $secondary-color;
    font-size: rem-calc(15);
    font-weight: bold;
    margin-bottom: 0;
  }

  p {
    color: $text-weak-color;
    font-size: rem-calc(12);
  }
}

.seller-detail-schedule {
  background-color: $pale-grey-two;
  padding: 1rem rem-calc(10) 3rem;
}

.seller-detail-evaluate {
  background-color: $white;
  box-shadow: 0 3px 20px $pale-grey-three;
  margin: 0 auto;
  max-width: 590px;
  padding: rem-calc(10);

  @include breakpoint(large up) {
    margin: 3rem auto;
    padding-bottom: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &__description {
    display: flex;
    padding: 1rem 1rem .5rem;

    @include breakpoint(large up) {
      padding-left: 0rem;
    }

    i {
      color: $secondary-color;
      font-size: rem-calc(36);
      line-height: 2.2rem;
      margin-right: rem-calc(5);
    }

    >div {
      h4 {
        color: $secondary-color;
        font-size: rem-calc(18);
        margin-bottom: 0;
      }

      p {
        font-size: rem-calc(14);
      }
    }
  }

  &__date {
    background-color: $pale-blue;
    border-radius: 4px;
    display: flex;
    padding: rem-calc(15);
  }

  &__date-day {
    border-right: 1px solid $secondary-color;
    color: $secondary-color;
    height: 42px;
    line-height: 1.7rem;
    margin-right: 1rem;
    padding-right: 1rem;


    span {
      font-size: rem-calc(30);
    }

    p {
      font-size: rem-calc(12);
      font-weight: bold;
      line-height: .5rem;
    }
  }

  &__date-description {
    @include breakpoint(large up) {
      align-items: center;
      display: flex;
      flex: auto;
      justify-content: space-between;
    }

    h5 {
      color: $secondary-color;
      font-size: rem-calc(18);
      margin-bottom: 0;
    }

    p {
      font-size: rem-calc(12);
      margin-bottom: rem-calc(10);
    }

    .button {
      border-color: $secondary-color;
      color: $secondary-color;
      margin-bottom: 0;
      padding: .7rem 1rem;
    }
  }
}

.seller-detail-account {
  padding: 2rem rem-calc(14);

  @include breakpoint(large up) {
    padding-bottom: 4rem;
    padding-top: 4rem;
  }

  &__container {
    margin: 0 auto;
    max-width: 590px;
  }

  h2 {
    color: $text-strong-color;
    font-size: rem-calc(24);
    margin-bottom: 1.4rem;
  }

  ul {
    @extend %u-normalize-list;
  }

  li,
  &__total {
    display: flex;
    justify-content: space-between;

  }

  li {
    margin: 1.2rem 0;
  }

  &__total {
    border-top: 1px solid $pale-grey;
    color: $text-strong-color;
    font-size: rem-calc(18);
    font-weight: bold;
    padding-top: 1.5rem;
  }
}
