//
// ONLY UTILITY FILE
//

.u-row-wrapper {
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;

  @include breakpoint(1600) {
    max-width: 1300px;
  }
}

%u-border-bottom {
  &::after {
    background-color: $primary-color;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 28px;
  }
}

%u-normalize-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.chevron::before {
	border-style: solid;
	border-width: 1px 1px 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 0.15em;
	position: relative;
	top: 0.15em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 0.45em;
}

.chevron.rigth:before {
	left: 0;
	transform: rotate(45deg);
}

.chevron.bottom:before {
	top: 0;
	transform: rotate(135deg);
}

.chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}
