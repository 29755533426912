.login {
  background-color: $white;
  bottom: 0;
  box-shadow: 0 2px 20px rgba($cool-grey, .3);
  display: none;
  left: 0;
  outline: 0;
  padding-top: 1.5rem;
  position: fixed;
  right: 0;
  top: 0;

  p {
    font-size: rem-calc(12);
    text-align: center;
    padding-bottom: 0;
  }

  &::after {
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $white;
    content: '';
    height: 0;
    position: absolute;
    right: 2rem;
    top: -1.2rem;
    transform: rotate(180deg);
    width: 0;
  }

  @include breakpoint(large up) {
    bottom: auto;
    left: auto;
    padding-top: 0;
    position: absolute;
    right: 0;
    top: 4rem;
    width: 300px;
  }

  .mdi-close {
    font-size: rem-calc(20);
    position: absolute;
    right: 1.5rem;
    top: 1rem;
  }

  a,
  button {
    outline: none;
    width: 100%;
  }

  &__form {
    padding: 1.5rem;
  }

  &__form-group {
    input {
      margin-bottom: rem-calc(8);
    }

    &:nth-child(2) {
      margin-top: 1.5rem;
    }
  }

  &__invalid-message {
    color: $red;
    font-size: .9rem;
    text-align: left;
  }

  &__forget {
    color: $text-weak-color;
    display: block;
    font-size: rem-calc(12);
    margin-bottom: 1.4rem;
  }

  &__no-account {
    font-size: .85rem;
    text-align: left;
  }

  &__social {
    height: 100%;
    padding: 1.5rem;
    text-align: center;
    padding-bottom: 0;

    p {
      font-size: rem-calc(12);
      text-align: center;
    }

    a {
      font-weight: 400;
      padding-left: 2.8rem;
      text-align: left;

      @include breakpoint(large up) {
        font-size: rem-calc(15);
      }
    }
  }

  &__social-twitter,
  &__social-google,
  &__social-facebook {
    position: relative;

    i {
      font-size: rem-calc(20);
      left: rem-calc(12);
      position: absolute;
      top: rem-calc(10);

      @include breakpoint(large up) {
        font-size: rem-calc(24);
      }
    }
  }

  &__social-twitter {
    background-color: #2AA3EF;

    &:hover {
      background-color: #2AA3EF;
    }
  }

  &__social-facebook {
    background-color: #3D5A96;

    &:hover {
      background-color: #3D5A96;
    }
  }

  &__social-google {
    background-color: #dc4e41;

    &:hover {
      background-color: #dc4e41;
    }
  }
}
