$xibilla-blue: #0f80c1;

.ck-filters {
  background-color: white;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: -110%;
  bottom: 0;
  overflow-y: auto;
  z-index: 3;
  background-color: white;
  border-bottom: none;
  -webkit-overflow-scrolling: touch;

  &.active {
    left: 0;
    .filter_actions,
    .filter-controls {
      opacity: 1;
    }
  }

  @include breakpoint(768) {
    display: block;
    position: relative;
    left: 0;
    .filter_actions,
    .filter-controls {
      opacity: 1;
    }
  }

  .filter_actions {
    position: absolute;
    top: 0;
    background-color: white;
    width: 100%;
    padding: 15px;
    display: grid;
    transition: all 0.5s;
    border-bottom: 1px solid #eee;

    @include breakpoint(large) {
      display: none;
    }

    a {
      display: inline-block;
      background-color: $xibilla-blue;
      color: white;
      padding: 10px 15px;
      font-size: 0.8rem;
      border-radius: 3px;
      justify-self: start;
    }
  }

  .filter-controls {
    background-color: white;
    padding-top: 60px;
    transition: all 0.5s;

    @include breakpoint(large) {
      opacity: 1;
      padding-top: 40px;
    }

    .filters_title {
      display: none;

      @include breakpoint(large) {
        display: block;
        padding: 0 15px 30px 15px;
      }
    }

    .this_filter {
      text-align: left;
      padding: 30px 15px 10px 15px;
      transition: all 0.2s;
      border-top: 1px solid #eee;

      &:last-child {
        border-bottom: 1px solid #eee;
      }

      @include breakpoint(large) {
        padding-left: 15px;
      }

      p {
        font-weight: bold;
        font-size: 0.8rem;
        text-transform: uppercase;

        a {
          color: #777;
          display: inline-block;
          width: 100%;
          position: relative;
          transition: all 0.2s;

          &:hover {
            color: $xibilla-blue;
          }

          i {
            font-size: 1.4rem;
            line-height: 1.4rem;
            position: absolute;
            top: 2px;
            right: 0;
          }
        }
      }

      ul,
      .tabs {
        display: none;
      }

      i.mdi-chevron-up {
        display: none;
      }

      i.mdi-chevron-down {
        display: block;
      }

      .available_types {
        display: none;
      }

      &.open {
        i.mdi-chevron-down {
          display: none;
        }

        i.mdi-chevron-up {
          display: block;
        }

        ul,
        .tabs {
          display: block;
        }

        .available_types {
          display: grid;
        }

        .filter-toggle {
          color: $xibilla-blue;
        }

        ul.year-list {
          display: grid;
        }

        ul.lista-placas {
          display: grid;
        }
      }

      &.tipo_vehiculo {
        .available_types {
          grid-template-columns: 1fr 1fr;
          grid-column-gap: 10px;
          grid-row-gap: 10px;
          grid-auto-rows: 1fr;
          margin-bottom: 20px;
          margin-top: 20px;

          .esta_carroceria {
            text-align: center;
            border: 1px solid #eee;
            padding: 10px 0;
            transition: all 0.2s;
            cursor: pointer;
            border-radius: 2px;

            p {
              text-transform: none;
              font-size: 0.7rem;
              margin-bottom: 0;
              line-height: 0.7rem;
              margin-top: 3px;
            }

            &.is-active {
              border: 1px solid $xibilla-blue;

              p {
                color: $xibilla-blue;
              }
            }

            @include breakpoint(large) {
              &:hover {
                border: 1px solid $xibilla-blue;

                p {
                  color: $xibilla-blue;
                }
              }
            }
          }
        }
      }

      &.list {
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          margin-bottom: 20px;

          li {
            width: 100%;
            a {
              color: #777;
              i {
                opacity: 0;
                transition: all 0.2s;
              }

              &.active {
                color: $xibilla-blue;

                i {
                  opacity: 1;
                }
              }

              @include breakpoint(large) {
                &:hover {
                  color: $xibilla-blue;

                  i {
                    opacity: 1;
                  }
                }
              }
            }
          }

          li.no-featured {
            display: none;

            &.visible {
              display: inline-block;
            }
          }

          &.blocks {
            li {
              display: inline-block;
              width: 100%;
              margin-bottom: 10px;
              text-align: center;

              a {
                display: inline-block;
                width: 100%;
                text-align: center;
                border: 1px solid $xibilla-blue;
                color: $xibilla-blue;
                padding: 8px;
                transition: all 0.2s;
                // max-width: 150px;
                font-size: 0.8rem;
                border-radius: 2px;
                font-weight: 500;

                &.active {
                  background-color: $xibilla-blue;
                  color: white;
                }

                @include breakpoint(large) {
                  &:hover {
                    background-color: $xibilla-blue;
                    color: white;
                  }
                }
              }
            }

            &.year-list {
              grid-template-columns: 1fr 1fr;
              grid-row-gap: 5px;
              grid-column-gap: 5px;

              li:first-child,
              li:last-child {
                grid-column-start: 1;
                grid-column-end: 3;
              }
            }

            &.lista-placas {
              grid-template-columns: 1fr 1fr;
              grid-row-gap: 5px;
              grid-column-gap: 5px;

              li:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
              }
            }
          }
        }

        .tabs {
          padding-left: 20px;
          margin-top: 15px;
          margin-bottom: 20px;
          a {
            font-size: 0.8rem;
            display: inline-block;
            color: $xibilla-blue;
            font-weight: 500;
          }
        }

        &.brands {
          position: relative;

          ul {
            margin-bottom: 20px;
          }
        }

        .view-all{
          span.ver{
            display: block;
          }

          span.ocultar{
            display: none;
          }

          &.ocultar{
            span.ocultar{
              display: block;
            }

            span.ver{
              display: none;
            }
          }
        }
      }
    }
  }
}
