// Foundation Core
@import './extra/color';
@import './extra/material';
@import './modules';

// Utility
@import './utility';

//
// Import Containers Styles
//
@import './partials/_index';
@import './components/_index';
@import './pages/_index';

// Grid styles

div#main {
	color: $text-color;
	display: grid;
	font-family: 'Montserrat', sans-serif;
	grid-template-columns: 1fr;
	justify-items: center;
	overflow-x: hidden;

	.wrapper {
		width: 100%;
	}
}

input:not([type='password']):not([pattern='email']):not([name='_username']):not([name='search_car']) {
	// text-transform: uppercase;
}

// a:([href='/terms']):([href='/privacy']){
// 	color: #1d71bb;
// }
