.state-chip {
	background-color: $secondary-color;
	border-radius: 25px;
	color: $white;
	font-size: rem-calc(12);
	font-weight: bold;
	padding: rem-calc(2) .9rem rem-calc(2) 1.59rem;
	position: relative;

	@include breakpoint(large up) {
		margin-right: 1rem;
	}

	&::before {
		background-color: $white;
		border-radius: 50%;
		content: '';
		height: 10px;
		left: 10px;
		position: absolute;
		top: 5.5px;
		width: 10px;
	}
}

.large-state-chip {
	color: $secondary-color;
	font-size: rem-calc(12);
	font-weight: bold;
	padding: rem-calc(2) .9rem rem-calc(2) 1.59rem;
	position: relative;

	&::before {
		background-color: $secondary-color;
		border-radius: 50%;
		content: '';
		height: 10px;
		left: 10px;
		position: absolute;
		top: 5.5px;
		width: 10px;
	}
}
