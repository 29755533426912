.faqs-list {
  background-color: $pale-grey-two;
  padding: 3rem 1rem;

  @include breakpoint(large up) {
    padding: 4rem 0;
  }

  .accordion {
    @extend %u-normalize-list;

    margin-top: 3rem;
  }

  .accordion-item {
    border-top: 1px solid $pale-grey-three;

    &:last-child {
      border-bottom: 1px solid $pale-grey-three;
    }
  }

  .accordion-title,
  .accordion-content {
    padding-left: 2rem;
  }

  .accordion-title {
    color: inherit;
    display: block;
    font-weight: bold;
    padding-bottom: 1.2rem;
    padding-top: 1.2rem;
    position: relative;

    i {
      color: $pale-grey;
      font-size: 1.2rem;
      left: 0;
      position: absolute;
      top: 1.1rem;

      &:last-child {
        display: none;
      }
    }
  }

  .accordion-content {
    display: none;

    p {
      font-size: rem-calc(14);
    }
  }

  .is-active {
    .accordion-title {
      color: $secondary-color;

      i:first-child {
        display: none;
      }

      i:last-child {
        color: $secondary-color;
        display: block;
      }
    }
  }
}
