//
// ONLY HOME PAGE
//

.hero {
  background-color: $blue-grey-dark;
  background-image: url("https://placehold.it/750x607");
  background-position: 50%;
  background-size: cover;
  color: $white;
  min-height: 300px;
  position: relative;

  @include breakpoint(large up) {
    background-image: url("https://placehold.it/1400x500");
    height: 500px;
  }

  &__container {
    margin: 0 auto;
    padding: 3rem rem-calc(10);
    position: relative;

    @include breakpoint(large up) {
      align-items: center;
      display: flex;
      height: inherit;
    }

    @include breakpoint(1200) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .hero-info {
    h1 {
      font-size: rem-calc(24);
      margin-bottom: 2rem;
      max-width: 290px;

      @include breakpoint(large up) {
        font-size: rem-calc(40);
        max-width: 450px;
      }
    }

    &__cta {
      a:first-child {
        margin-right: 1rem;

        @include breakpoint(large up) {
          margin-right: 1.5rem;
        }
      }
    }
  }
}

.banners {
  padding: 3rem 10px 2rem;

  @include breakpoint(large) {
    padding: 5rem 10px 3rem;
  }

  .u-row-wrapper {
    display: grid;
    grid-gap: 2rem;

    @include breakpoint(medium up) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .banner {
    text-align: center;
    border: 1px solid #d8d8d8;
    box-shadow: 0 4px 20px rgba($black, 0.1);

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.benefits {
  padding: 3rem rem-calc(10);

  @include breakpoint(large) {
    padding: 3rem 0;
  }

  h3 {
    @extend %u-border-bottom;
    font-size: rem-calc(16);
    margin-bottom: 2.5rem;
    padding-bottom: 0.6rem;
    position: relative;
    text-align: center;
  }

  // &__container {
  // @include breakpoint(large up) {
  //   display: grid;
  //   grid-gap: 2rem;
  //   grid-template-columns: repeat(5, 1fr);
  // }
  // }

  .benefit {
    display: flex;
    margin-bottom: 1.8rem;

    // @include breakpoint(large up) {
    //   align-items: center;
    //   flex-direction: column;
    //   text-align: center;
    // }

    @include breakpoint(large) {
      padding: 0 20px;
    }

    &__icon {
      border: 1px solid $primary-color;
      border-radius: 50%;
      flex-shrink: 0;
      height: 65px;
      line-height: 65px;
      text-align: center;
      width: 65px;

      i {
        color: $primary-color;
        font-size: rem-calc(35);
      }

      @include breakpoint(large up) {
        margin-top: 20px;
      }
    }

    &__description {
      margin-left: 1rem;

      // @include breakpoint(large up) {
      //   margin-left: 0;
      // }

      h4 {
        color: $secondary-color;
        font-size: 1rem;
        margin-bottom: 0;

        @include breakpoint(large up) {
          margin: 1.2rem 0 0.4rem;
        }
      }

      p {
        color: $text-weak-color;
        font-size: rem-calc(14);
        margin-bottom: 0;
      }
    }

    &:nth-child(2) {
      i {
        font-size: rem-calc(30);
        margin-left: -0.3rem;
      }
    }
  }
}

.how {
  background-color: $pale-grey-two;
  padding-top: 3rem;

  @include breakpoint(xlarge up) {
    padding: 6rem 0 4rem;
  }

  &__container {
    display: grid;
    grid-auto-columns: 200px;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 200px);
    overflow-x: scroll;
    padding: 4rem 0;
    width: 100vw;

    @include breakpoint(1000) {
      grid-template-columns: repeat(4, 1fr);
      margin-top: 5rem;
      width: 100%;
    }
  }

  .how-step {
    border-right: 1px solid $border-color;
    padding: 0 2rem 0 1.7rem;

    span {
      color: $text-weak-color;
      font-size: rem-calc(48);
      font-weight: bold;
    }

    h4 {
      font-size: rem-calc(18);
      font-weight: bold;
      line-height: 1.2rem;
      margin-bottom: 0.4rem;
    }

    p {
      color: $text-weak-color;
      font-size: rem-calc(12);
      max-width: 170px;
      text-align: justify;
    }

    &:first-child {
      max-width: 240px;
      padding-left: rem-calc(10);

      @include breakpoint(large up) {
        padding-left: rem-calc(20);
      }
    }

    &:last-child {
      border-right: 0;
    }
  }
}

.news {
  background-color: white;
  padding: 3.5rem 0;
  position: relative;

  @include breakpoint(large up) {
    padding: 6rem 0 4rem;
  }

  h3 {
    @extend %u-border-bottom;
    font-size: rem-calc(16);
    padding-bottom: 0.6rem;
    position: relative;
    text-align: center;
  }
}

.about {
  background-color: $secondary-color;
  color: $white;
  font-family: inherit;

  @include breakpoint(large up) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__copy {
    padding: 2rem rem-calc(10);

    @include breakpoint(large up) {
      align-self: center;
      justify-self: center;
      width: 75%;
    }

    h3 {
      font-size: rem-calc(24);
      margin-bottom: 1.3rem;
    }

    p {
      font-size: rem-calc(14);
      line-height: 1.5rem;

      @include breakpoint(large up) {
        font-size: 1rem;
        line-height: 2.4rem;
      }
    }
  }

  &__hero {
    img {
      height: 100%;
      width: 100%;
    }
  }
}

.logos {
  background-color: #ebebeb;
  padding: 20px 10px;

  .logos__container {
    display: grid;
    grid-row-gap: 20px;
    grid-column-gap: 10px;

    @include breakpoint("medium") {
      display: block;
      text-align: left;
      height: 65px;
    }

    .colserauto {
      text-align: center;
      img {
        max-width: 100px;
      }

      @include breakpoint("medium") {
        text-align: left;
        float: left;
        margin-right: 20px;

        img {
          max-width: 100px;
        }
      }

      @include breakpoint("large") {
        margin-right: 30px;
      }
    }

    .santanter {
      text-align: center;
      img {
        max-width: 100px;
      }
      @include breakpoint("medium") {
        text-align: left;
        float: left;
        margin-right: 20px;

        img {
          max-width: 100px;
          padding-top: 24px;
        }
      }

      @include breakpoint("large") {
        margin-right: 30px;
      }
    }

    .maphre {
      text-align: center;
      padding-top: 15px;
      img {
        max-width: 150px;
      }
      @include breakpoint("medium") {
        text-align: left;
        float: left;
        padding-top: 23px;

        img {
          max-width: 150px;
        }
      }
    }

    .ibm {
      text-align: center;
      padding-top: span {
        display: inline-block;
        width: 100%;
        font-size: 0.8rem;
      }
      img {
        margin-left: 15px;
        max-width: 60px;
      }

      @include breakpoint("medium") {
        text-align: left;
        float: right;
        padding-top: 23px;
        span {
          font-size: 0.6rem;
        }
        img {
          max-width: 60px;
        }
      }
    }
  }
}

footer {
  .logos {
    @include breakpoint("medium") {
      padding: 40px 10px 0px 10px;
    }
  }
}

.mantenimiento {
  text-align: center;
  padding: 30px;
  padding-top: 150px;
  padding-bottom: 200px;

  h1 {
    color: $secondary-color;
  }

  i {
    font-size: 4rem;
  }
}
