.contact-help {
  padding: 2rem 1rem;

  @include breakpoint(large up) {
    padding: 4rem 1rem;
  }

  @include breakpoint(xlarge up) {
    padding-left: 0;
    padding-right: 0;
  }

  &__container {
    @include breakpoint(large up) {
      display: flex;
      justify-content: space-between;
    }
  }

  p {
    color: $secondary-color;
    font-size: rem-calc(20);

    @include breakpoint(large up) {
      font-size: rem-calc(28);
      margin-bottom: 0;
    }
  }

  &__suggestions {
    @include breakpoint(large up) {
      display: flex;
    }
  }

  .contact-help-suggest {
    align-items: center;
    display: flex;
    margin-bottom: 1rem;

    @include breakpoint(large up) {
      margin-bottom: 0;

      &:first-child {
        margin-right: 3rem;
      }
    }

    &__icon {
      border: 1px solid $secondary-color;
      border-radius: 50%;
      color: $secondary-color;
      height: 40px;
      line-height: 40px;
      text-align: center;
      width: 40px;

      i {
        font-size: 1.2rem;
      }
    }

    &__copy {
      font-size: rem-calc(14);
      font-weight: bold;
      margin-left: rem-calc(10);

      a {
        color: inherit;

        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
}
