.carousel {
	margin: 0 auto;
	opacity: 0;
	padding: 1rem 0;
	position: relative;
	width: 100vw;

	@include breakpoint(large up) {
		&__overlay-left,
		&__overlay-right {
			height: 60%;
			position: absolute;
			top: 28%;
			width: 70px;
			z-index: 10;
		}

		&__overlay-left {
			// background: linear-gradient(90deg, $blue-grey-dark 10%, rgba($blue-grey-dark, .75) 50%, transparent);
			left: 0;
		}

		&__overlay-right {
			// background: linear-gradient(270deg, $blue-grey-dark 10%, rgba($blue-grey-dark, .75) 50%, transparent);
			right: 0;
		}
	}

	.slick-track {
		align-items: center;
		display: flex;
		padding: 1rem 0;
		padding-top: 50px;

		@include breakpoint(large up) {
			left: 4rem;
		}
	}

	.slick-slide {
		height: 260px;
	}

	&__reel-left,
	&__reel-right {
		color: $white;
		cursor: pointer;
		font-size: 3rem;
		position: absolute;
		top: 40%;
		z-index: 20;
	}

	&__reel-left {
		left: 0;
	}

	&__reel-right {
		right: 0;
	}
}

.car-card {
	background-color: $grey-dark;
	margin: 0 rem-calc(5);
	outline: none;
	transform: scale(1);
	// transition: transform .25s ease;
	width: 300px;

	&__info {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: .7rem;
	}

	&__info-description {
		p {
			color: $white;
			font-size: .8rem;
			margin-bottom: 0;
			position: relative;
			width: 100%;
		}
	}

	&__info-ref {
		display: inline-block;
		line-height: .6rem;
		max-width: 220px;
		overflow: hidden;
		font-weight: bold;
		white-space: nowrap;
	}

	&__info-year {
		border: 1px solid $pale-grey-three;
		border-radius: .1rem;
		font-size: rem-calc(10);
		font-weight: bold;
		margin-left: .5rem;
		padding: .1rem .6rem;
	}

	&__info-kms {
		color: $text-weak-color;
		font-size: rem-calc(11);
		margin-top: .15rem;
		margin-bottom: 5px;
	}

	&__info-price {
		color: $white;
		font-weight: bold;
		font-size: 1.1rem;
	}

	&__info-price-top {
		display: none;
	}

	&__image {
		img {
			height: 170px;
			width: 100%;
		}
	}
}

.car-card:hover {
	@include breakpoint(large up) {
		background-color: $white;
		cursor: pointer;
		transform: scale(1.125);
		visibility: visible;
		position: relative;
		z-index: 1;
		transition: 0.2s ease-in-out;

		.car-card__info-kms-top {
			line-height: 20px;
		}

		.car-card__info-price-top {
			color: $secondary-color;
			float: right;
			display: block;
			font-weight: bold;
			font-size: 1.0rem;
		}

		.car-card__info-price {
			color: $secondary-color;
		}

		.car-card__info-description {
			p {
				color: $text-weak-color;
				font-weight: bold;
			}
		}
	}
}

.slick-current {
	@include breakpoint(medium down) {
		background-color: $white;
		cursor: pointer;
		transform: scale(1.15);
		transition: transform .4s;
		visibility: visible;
		z-index: 10;

		.car-card__image {
			img {
				// height: 178px;
			}
		}

		.car-card__info {
			align-items: center;
			flex-direction: row;
			padding: .5rem .4rem;

			p {
				color: $text-strong-color;
				font-size: .7rem;

				span {
					font-size: .55rem;
					margin: 0;
					margin-left: .2rem;
					padding: .1rem .3rem;
				}
			}
		}

		.car-card__info-description {
			width: 65%;
		}

		.car-card__info-kms {
			font-size: rem-calc(9);
		}

		.car-card__info-price {
			color: $secondary-color;
			font-size: rem-calc(12);
			margin-top: 0;
			text-align: right;
			transition: font-size .05s;
			width: 35%;
		}
	}
}
