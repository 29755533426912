.user-tabs {
  border: 1px solid $pale-grey;
  border-radius: 6px;
  color: $text-weak-color;
  display: flex;
  margin: 0 auto;
  width: 280px;

  .user-tab {
    cursor: pointer;
    font-size: rem-calc(12);
    font-weight: bold;
    padding: .7rem 0;
    text-align: center;
    width: 50%;
  }
}

.user-tab--active {
  background-color: $secondary-color;
  color: $white;
}
