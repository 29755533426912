//
// ONLY FOOTER PARTIAL
//

.footer {
	background-color: #e8ecee;

	&__container {
		display: grid;
		grid-auto-flow: dense;
		grid-row-gap: 15px;
		grid-template-columns: repeat(12, 1fr);
		padding: 15px 10px 10px;

		@include breakpoint(large up) {
			align-items: center;
			padding: 1.5rem 0;
		}
	}

	.footer-copyrigth-notice {
		align-self: center;
		grid-column: 1 / 9;

		@include breakpoint(large up) {
			grid-column: 10 / -1;
		}

		p {
			color: #62656f;
			font-size: rem-calc(12);
			font-weight: 600;
			line-height: rem-calc(12);
      margin: 0;
      text-align: right;
		}

		img {
			margin-top: 20px;
		}
	}

	.footer-social-media-links {
		align-items: center;
		align-self: center;
		justify-items: center;
		grid-column: 10/13;
		grid-column-gap: 10px;
		grid-template-columns: repeat(3, 1fr);
		display: grid;

		@include breakpoint(large up) {
			grid-column: 8 / 10;
			margin-right: 1rem;
		}

		a {
			color: white;
			font-size: 20px;
			line-height: 20px;
		}
	}

	.footer-terms-links {
		font-size: rem-calc(9);
		grid-column: 1/13;

		@include breakpoint(large up) {
			font-size: rem-calc(10);
			grid-column: 1 / 6;
		}

		p {
			margin-bottom: 3px;
		}

		a {
			color: #62656f;
			display: inline-block;
			font-weight: 600;
			margin-right: 10px;
		}
	}
}
