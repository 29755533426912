$xibilla-blue: #0f80c1;
$xibilla-labels: #0f80c1;
$xibilla-red: red;

.ck-card-tools{
  padding: 15px;
  display: grid;
  row-gap: 20px;

  @include breakpoint(768){
    grid-template-columns: auto 200px;
    align-items: end;
  }

  .total{
    color: $xibilla-blue;
  }

  .order-tool{
    label{
      color: #333;
    }
    select{
      height: 40px;
      font-size: .8rem;
      background-position: right 0 center;
      padding: 5px 10px;
      border: 1px solid #eee;
      border-radius: 3px;
      margin-bottom: 0;
      color: #444;
    }
  }
}

.ck-empty{
  text-align: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;

  a{
    color: $xibilla-blue;
  }
}

.ck-cards, .ck-market{
  display: grid;
  padding: 15px;
  background-color: #f8fafb;
  row-gap: 15px;

  @include breakpoint(768){
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    padding: 15px;
    grid-auto-rows: 1fr;
  }

  @include breakpoint(1200){
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint(1650){
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}


.ck-market-title{
  padding: 20px;
  padding-top: 40px;
  h3{
    color: #0f80c1;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
  }
}

.ck-loading{

  .ck-loader {
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .car {
    &__body {
      animation: shake 0.2s ease-in-out infinite alternate;
    }

    &__line {
      transform-origin: center right;
      stroke-dasharray: 22;
      animation: line 0.8s ease-in-out infinite;
      animation-fill-mode: both;

      &--top {
        animation-delay: 0s;
      }

      &--middle {
        animation-delay: 0.2s;
      }

      &--bottom {
        animation-delay: 0.4s;
      }
    }
  }
}


@keyframes shake {
  0% {
    transform: translateY(-1%);
  }
  100% {
    transform: translateY(3%);
  }
}

@keyframes line {
  0% {
    stroke-dashoffset: 22;
  }

  25% {
    stroke-dashoffset: 22;
  }

  50% {
    stroke-dashoffset: 0;
  }

  51% {
    stroke-dashoffset: 0;
  }

  80% {
    stroke-dashoffset: -22;
  }

  100% {
    stroke-dashoffset: -22;
  }
}


.news{
  display: none;
  background-color: #f7f7f7 !important;
}

.relacionados{
  display: grid;
  row-gap: 15px;

  @include breakpoint(768){
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
    padding: 50px 20px;
    grid-auto-rows: 1fr;
  }

  @include breakpoint(1200){
    grid-template-columns: 1fr 1fr 1fr;
    padding: 100px 50px;
  }

  @include breakpoint(1650){
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.this_card{
  border: 1px solid #eee;
  background-color: white;
  transition: all .2s;
  display: grid;
  background-color: white;

  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }

  &.hidden{
    display: none;
  }

  .thumbnail{
    position: relative;
    background-color: transparent;
    display: grid;
    align-items: center;

    img{
      width: 100%;
    }

    .brand_container{
      position: absolute;
      bottom: 15px;
      right: 15px;
      width: 40%;
      border-radius: 3px;
      background-color: white;
      text-align: center;
    }

    .separado{
      display: none;

      &.prepurchase{
        display: block;
        position: absolute;
        color: white;
        background-color: red;
        padding: 5px;
        right: -3px;
        top: -3px;
        font-weight: bold;
        font-size: rem-calc(12);
        text-transform: uppercase;
      }
    }
  }

  .content{
    align-self: end;
    position: relative;
    padding: 10px;
    padding-top: 20px;
    border-top: 1px solid #eee;
    display: grid;
    background-color: white;

    .title{
      position: relative;

      .anio{
        display: inline-block;
        border: 1px solid $xibilla-blue;
        color: $xibilla-blue;
        padding: 1px 8px;
        font-size: rem-calc(10);
        font-weight: bold;
        margin-bottom: 5px;
      }

      .name{
        display: inline-block;
        width: 100%;
        font-weight: bold;
        font-size: rem-calc(14);

        a{
          color: #666;
          &:hover{
            text-decoration: underline;
          }
        }
      }

      .video_disponible{
        color: #ff6000;
        position: absolute;
        top: 3px;
        right: 0;
        font-size: rem-calc(13);
      }
    }

    .meta{
      align-self: end;
      span{
        display: inline-block;
      }

      .referencia_exacta{
        text-transform: uppercase;
        width: 100%;
        font-size: rem-calc(10);
        line-height: 12px;
        color: #666;
        font-weight: bold;
      }

      .color{
        text-transform: uppercase;
        width: 100%;
        font-size: rem-calc(13);
      }

      .kilometraje{
        font-size: rem-calc(13);
      }

      .placa{
        font-size: rem-calc(13);
        border-left: 1px solid #aaa;
        padding-left: 5px;
      }

      .precio{
        font-weight: bold;
        color: $xibilla-blue;
        font-size: rem-calc(20);
        padding-top: 5px;
        width: 100%;

        &.promocion{
          .precio_regular{
            color: rgb(241, 70, 70);
            text-decoration: line-through;
            font-size: rem-calc(14px);
            display: inline-block;
          }
        }
      }
    }
  }
}
