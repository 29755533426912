.seller-hero {
  background-color: $blue-grey-dark;
  background-image: url("/img/desk_hero.png");
  background-position: 50% 70%;
  background-size: cover;
  height: 270px;
  position: relative;

  @include breakpoint(large up) {
    background-position: 50% 40%;
    height: 330px;
  }

  &__copy {
    color: $white;
    padding: 2rem 1rem 0;
    position: relative;
    z-index: 2;

    @include breakpoint(large up) {
      padding-top: 5rem;
      text-align: center;
    }

    h1 {
      font-size: rem-calc(25);

      @include breakpoint(large up) {
        font-size: rem-calc(40);
      }
    }

    p {
      color: $text-weak-color;
      line-height: 1.2rem;

      @include breakpoint(large up) {
        font-size: rem-calc(20);
      }
    }
  }

  &__overlay {
    background-color: $blue-grey-dark;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}

.seller-data {
  background-color: $pale-grey-two;

  @include breakpoint(1000) {
    padding: 4rem 0;
  }
}

.seller-data-container {
  background-color: $white;
  padding: 3rem 1rem;

  @include breakpoint(large up) {
    padding: 3rem 6rem;
  }

  @include breakpoint(1000) {
    box-shadow: 0 10px 25px $pale-grey-three;
  }
}

.login-request {
  h4,
  p,
  ol {
    margin-bottom: 1.5rem;
  }

  h4 {
    color: $cool-blue;
  }

  &__buttons {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoint(large up) {
      flex-direction: row;
      justify-content: flex-end;
    }

    .button {
      margin-bottom: 0;
    }

    .clear {
      font-size: rem-calc(14);
      color: $cool-blue;
      font-weight: normal;
      padding: 0;
      margin-bottom: 2rem;

      @include breakpoint(large up) {
        margin-right: 2rem;
        margin-bottom: 0;
      }

      &:hover {
        color: $cool-blue;
      }
    }
  }
}

.seller-data-user {
  &__form {
    @include breakpoint(1000) {
      .form_row {
        align-items: flex-start;
        display: grid;
        grid-column-gap: 1.5rem;
        grid-template-columns: 1fr 2fr 1fr;
      }

      .form_checks {
        // margin-top: 45px;

        .seller-info-data__terms {
          margin-bottom: 15px;
          a {
            color: #1d71bb;
          }
        }
      }

      a.button {
        width: auto;
        margin-left: calc(100% - 130px);
      }
    }
  }

  p {
    color: $text-strong-color;
    font-size: rem-calc(15);
    margin-bottom: 2rem;
    text-align: center;

    @include breakpoint(large up) {
      text-align: left;
    }
  }

  input,
  select {
    margin-bottom: 2rem;

    @include breakpoint(1000) {
      margin-bottom: 0;
    }
  }

  .seller-info-data__terms.is-invalid-input {
    label {
      color: red;
    }
    a {
      color: red;
    }
  }

  .error_required {
    visibility: hidden;
    font-size: 11px;
    color: red;
  }

  .error_required.is-invalid-input {
    visibility: visible;
  }

  .seller-info-data__terms {
    margin-bottom: -5px;
  }

  .button {
    margin-top: 2rem;
    width: 100%;

    @include breakpoint(1000) {
      margin-bottom: 0;
      width: 130px !important;
      height: 45px;
    }

    img {
      display: none;
      margin-top: -5px;
    }
  }
}

.seller-faqs {
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;

  @include breakpoint(medium up) {
    padding: 5rem 0 5rem 1rem;
  }

  @include breakpoint(large up) {
    padding: 5rem 0;
  }

  h2 {
    font-size: rem-calc(20);
    margin-bottom: 1.2rem;

    @include breakpoint(medium up) {
      font-size: rem-calc(24);
      margin-bottom: 1.8rem;
    }
  }

  &__list {
    @extend %u-normalize-list;

    @include breakpoint(large up) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    li {
      font-size: rem-calc(12);
      margin-bottom: 1rem;

      @include breakpoint(medium up) {
        font-size: rem-calc(14);
      }
    }
  }

  .button {
    border-color: $pale-grey;
    color: $pale-grey;
    font-size: rem-calc(12);
    margin-top: 2rem;
    width: 100%;

    @include breakpoint(medium up) {
      align-self: center;
      width: auto;
    }
  }
}

#loginRequired {
  .login_request {
    text-align: center;
    padding-top: 40px;

    img {
      max-width: 250px;
    }

    p.lead {
      font-weight: 100;
      margin-top: 30px;
      margin-bottom: 30px;
      color: #1d71bb;
    }

    .button {
      margin-top: 10px;
      display: inline-block;
    }
  }
}
