//
// ONLY SELECT2
// Utility for tables
//

@import '../../node_modules/select2/src/scss/core';

span.select2 {width: 100% !important}

.select2-container {
    .select2-selection {
        border: 0;
        border-bottom: 1px solid #e0e0e0;
        border-radius: 0;
        background-color: transparent;
        color: #424242;
        font-family: inherit;
        font-size: 1rem;
        line-height: normal;
        margin-bottom: 2rem;
        margin-top: -5px;
        &:focus, &:hover {
            border-bottom: 2px solid $primary-color;
            background-color: transparent;
        }
        .select2-selection__rendered {
            padding-left: 0;
            margin-left: 0.25rem;
            font-size: 0.875rem;
        }
        .select2-selection__arrow {
            height: 2.25rem;
            b {
                @include background-triangle($select-triangle-color);
                background-size: 9px 6px;
                background-position: $global-right (-$form-spacing) center;
                background-origin: content-box;
                background-repeat: no-repeat;
                padding-#{$global-right}: ($form-spacing * 1.5);
                background-origin: content-box;
                background-repeat: no-repeat;
                border: 0 none;
                height: 9px;
                margin-top: -1rem;
                border: 0;
            }
        }
    }
}

.select2-dropdown {
    border-radius: 0;
    border-color: #e0e0e0;
    margin-top: -2rem;
    .select2-results__message {
        font-size: 0.875rem;
        line-height: 1.2;
    }
    &.select2-dropdown--below {
      max-width: 100% !important;
    }
    .select2-search {
        .select2-search__field {
            border: 0;
            border-bottom: 1px solid #e0e0e0;
            box-shadow: none;
            border-radius: 0;
            width: 100% !important;
            &:focus, &:hover {
                border-bottom: 2px solid $primary-color;
                background-color: transparent;
            }
        }
    }
}

.search-filter, .filter-container {
  .select2-container {
    .select2-selection {
      // margin-bottom: 5rem;
      &.select2-selection--multiple, .select2-selection--single {
        border: 0;
        border-bottom: 2px solid $primary-color;
      }

      &.select2-selection--single {
        @include breakpoint(large up) {
          display: none;
          margin-top: 0.5rem;
          .select2-selection__arrow {
            top: 14px;
          }
        }
      }
      .select2-selection__clear {
        position: absolute;
        right: 0;
      }
      span.select2-selection__rendered {
        .select2-selection__clear {
          margin-right: 1rem;
        }
        .select2-search__field {
          width: 100% !important;
        }
      }
      ul.select2-selection__rendered {
        @include breakpoint( small down ) {
          font-size: 0.75rem;
        }
        li.select2-search {
          width: 100% !important;
          input {
            margin: 0;
            padding-top: 1rem;
            font-size: 0.875rem;
            width: 100% !important;
            box-shadow: none;
          }
        }
      }
    }
  }
  .select2-container.select2-container--default.select2-container--open {
    left: 0 !important;
    top: 0 !important;
    position: relative !important;
    .select2-dropdown {
      .select2-search--dropdown {
        padding-left: 0;
        padding-right: 0;
        .select2-search__field {
          margin-bottom: 0.25rem;
          padding-right: 1.75rem;
        }
        &:before {
          font-weight: normal;
          display: inline-block;
          font-family: "Material Design Icons";
          font-size: 0.875rem;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\F349';
          right: 0rem;
          position: absolute;
          top: 1rem;
        }
      }
      &.select2-dropdown {
        border: 0;
        position: relative;

        .select2-results {
          margin-top: 0.5rem;
        }
        .select2-results__option {
          max-height: 120px;
          &.select2-results__message {
            font-size: 0.75rem;
          }
        }
      }
    }

    .select2-results > .select2-results__options {
      max-height: 120px;
      font-size: 0.875rem;
      font-family: 'Montserrat', sans-serif;
      @include breakpoint( small down ) {
        font-size: 0.75rem;
      }
    }

  }

  @include breakpoint(medium down) {
    .select2-selection__rendered {
      .select2-selection__choice {
        background: #fff;
        border: 1px solid #1d71bb !important;
        color: #1d71bb;
        border-radius: 1px;
        font-size: 10px;
        font-weight: bold;
        float: none;
        margin-bottom: 0.5rem;
        &:after {
          font-weight: normal;
          display: inline-block;
          font-family: "Material Design Icons";
          font-size: inherit;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\F12C';
          float: right
        }
        .select2-selection__choice__remove {
          color: #1d71bb;
        }
        i {
          display: inline-block !important;
          margin-left: 2px;
        }
      }
    }
  }
}