
  $xibilla-blue: #0f80c1;
  $xibilla-yellow: yellow;

  section#search_xibilla{
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    position: relative;

    @include breakpoint(768){
      grid-template-columns: 280px auto;
    }

    .ck-vehicle-content{
      border: 1px solid #eee;
      background-color: #f8fafb;

      .hero-xibilla{
        padding: 15px;
        img{
          width: 100%;
        }
      }

      .ck-menu-toggle{
        width: 100%;
        padding: 15px;
        display: grid;
        border-bottom: 1px solid #eee;

        @include breakpoint(768){
          display: none;
        }

        a{
          display: inline-block;
          background-color: $xibilla-blue;
          color: white;
          padding: 10px 15px;
          font-size: 0.8rem;
          border-radius: 3px;
          justify-self: start;
          border: 1px solid $xibilla-blue;
        }
      }

      .ck-vehicle-add{
        position: fixed;
        bottom: 77px;
        right: 37px;

        a{
          color: $xibilla-yellow;
          border: 1px solid $xibilla-yellow;
          background-color: #fff6f3;
          font-size: 0.8rem;
          border-radius: 3px;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          text-align: center;
          display: grid;
          align-items: center;
          box-shadow: 0 10px 40px #e8ecee;

          i{
            font-size: 25px;
          }
        }

        @include breakpoint(1024){
          display: none;
        }
      }

      .ck-vehicle-toolbar{
        width: 100%;
        padding: 15px;
        display: grid;
        grid-template-columns: 1fr;
        border-bottom: 1px solid #eee;
        background-color: white;

        @include breakpoint(1024){
          grid-template-columns: 140px auto;
          column-gap: 100px;
        }

        &-cta{
          display: none;

          @include breakpoint(1024){
            display: inline-block;
            justify-self: start;

            a{
              color: $xibilla-yellow;
              border: 1px solid $xibilla-yellow;
              background-color: #fff6f3;
              padding: 10px 15px;
              font-size: 0.8rem;
              border-radius: 3px;
              justify-self: start;
              display: block;
              font-weight: bold;
            }
          }
        }

        &-search{
          display: grid;
          grid-template-columns: auto 86px;
          column-gap: 16px;
          width: 100%;
          max-width: 500px;
          justify-self: end;

          input{
            margin-bottom: 0;
            justify-self: end;
          }

          a{
            display: inline-block;
            background-color: $xibilla-blue;
            color: white;
            padding: 8px 15px;
            padding-right: 30px;
            font-size: 0.8rem;
            border-radius: 3px;
            justify-self: start;
            position: relative;

            i{
              position: absolute;
              top: 8px;
              right: 8px;
            }
          }
        }
      }

      .ck-status-filters{
        background-color: #f8fafb;
        padding: 15px;
        border-bottom: 1px solid #eee;

        .status-labels{
          display: inline-block;
          font-weight: bold;
          width: 100%;
          margin-bottom: 5px;
        }

        ul{
          list-style: none;
          margin: 0;
          padding: 0;
          display: inline-block;

          li{
            display: inline-block;
            background-color: white;
            border: 1px solid #eee;
            padding: 5px 10px 5px 10px;
            margin-bottom: 5px;
            margin-right: 5px;

            input{
              margin-bottom: 0;
            }

            .label{
              font-weight: normal;

              i{
                display: inline-block;
                border: 1px solid #eee;
                padding: 3px 5px;
                font-size: rem-calc(12);
                font-style: normal;
                margin-left: 5px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
