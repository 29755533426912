section.favorites {

  padding: 2rem 1rem;

	@include breakpoint(large up) {
    padding: 2rem 1rem 4rem;
    min-height: 900px;
	}

  .favorites-header {
    margin-bottom: 2rem;
    text-align: center;

    h1 {
      font-size: rem-calc(26);
      margin-bottom: 0;

      @include breakpoint(large up) {
        font-size: rem-calc(40);
        margin-bottom: rem-calc(10);
      }
    }

    p {
      color: $text-weak-color;
      line-height: 1rem;

      @include breakpoint(large up) {
        font-size: rem-calc(21);
      }
    }
  }

  .vehiculos-header {
    display: grid;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    padding-top: 40px;
    padding-bottom: 40px;
    grid-auto-rows: 1fr;
    width: 100%;

    @include breakpoint(550){
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(1025){
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include breakpoint(1281){
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 1200px;
    }

    p.estado{
      grid-column-start: 1;
      grid-column-end: 10;
    }

    div.car{
      display: inline-block;
      border: 1px solid #e8ecee;
      background-color: white;
      position: relative;
      text-align: left;

      @include breakpoint(large){
        transition: all .2s ease-in-out;
      }

      @include breakpoint(large){
        &:hover{
          transform: scale(1.1);
        }
      }

      &__image{
        width: 100%;
        position: relative;

        img{
          width: 100%;
        }

        i{
          position: absolute;
          right: 10px;
          top: 5px;
          cursor: pointer;
          transition: all .2s;

          &:hover{
            color: red;
          }

          &.mdi-heart{
            color: red;
          }
        }
      }

      &__shadow{
        position: absolute;
        background-color: black;
        left: 0;
        right: 0;
        top: 30px;
        bottom: 0;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
      }

      &__info{
        width: 100%;
        padding: 10px 7px;
        cursor: pointer;
        z-index: 3;
        position: relative;
        background-color: white;

        &-description{
          p{
            font-weight: 600;
            font-size: .9rem;
            line-height: 1.1rem;
            display: block;
            margin-bottom: 5px;
            height: 40px;

            span{
              display: inline-block;
              border: 1px solid #eee;
              border-radius: 2px;
              padding: 2px 4px;
              font-size: .8rem;
              font-weight: 400;
            }
          }
        }

        &-kms{
          color: #777;
          font-size: 12px;
        }

        &-price{
          color: #1d71bb;
          font-weight: 600;
          font-size: 1.1rem;
          margin-top: 0px;
        }

        &-price-monthly{
          color: #777;
          font-weight: 600;
          font-size: .8rem;
        }
      }
    }
  }

}
