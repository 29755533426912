.dashboard {
	padding: 2rem 1rem;

	@include breakpoint(large up) {
    padding: 2rem 1rem 4rem;
    min-height: 800px;
	}
}

.dashboard-header {
	margin-bottom: 2rem;
	text-align: center;

	h1 {
		font-size: rem-calc(26);
    margin-bottom: 0;

		@include breakpoint(large up) {
			font-size: rem-calc(40);
			margin-bottom: rem-calc(10);
		}
	}

	p {
		color: $text-weak-color;
		line-height: 1rem;

		@include breakpoint(large up) {
			font-size: rem-calc(21);
		}
	}
}

.cars-list {
	display: grid;
  justify-items: center;
  text-align: center;

	@include breakpoint(1000) {
		padding: 0;
	}

	.vehiculos-header {
		width: 100%;

		p.estado {
			text-align: center;
			margin-top: 20px;
			margin-bottom: 30px;
			&:not(:first-of-type) {
				margin-top: 100px;
				font-weight: bold;
			}
		}

		.button {
			color: $text-weak-color !important;
      font-size: rem-calc(12);
      justify-self: center;
      display: inline-block;
      margin: 10px;

      &:hover{
        background-color: $secondary-color;
        border: 1px solid white;
        color: white !important;
      }
		}
	}
}

.dashboard-car {
	box-shadow: 0 3px 10px 1px $pale-grey-three;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  max-width: 350px;
  border: 1px solid #fff;

	@include breakpoint(large up) {
		margin-bottom: 1rem;
		align-items: center;
		border: 1px solid $pale-grey-three;
		box-shadow: 0 10px 50px $pale-grey-three;
		display: grid;
		grid-template-columns: 190px 3fr 1fr 220px;
		max-width: 100%;
    width: 100%;
    transition: all .2s;
    height: auto;

    &:hover{
      border: 1px solid #1d71bb;
    }
	}

	&__info,
	&__evaluate {
    padding: 1rem;
    &-state{
      text-align: center;
      .state-chip{
        margin: 0 auto;
      }
    }
	}

	&__info {
		border-bottom: 1px solid $pale-grey-three;
		padding-bottom: 1rem;

		@include breakpoint(large up) {
			border-bottom: 0;
			padding-bottom: 0;
		}
	}

	&__info-title {
		align-items: center;
		color: $text-strong-color;
		display: flex;

		span {
			border: 1px solid $pale-grey-three;
			border-radius: 1.8px;
			color: $text-color;
			font-size: rem-calc(12);
			font-weight: bold;
			margin-left: rem-calc(10);
      padding: 0 .7rem;
      align-self: flex-start;
    }

    div{
      text-align: left;
    }
	}

	&__info-description {
		color: $text-weak-color;
    font-size: rem-calc(12);
    text-align: left;
	}

	&__info-price {
    font-size: rem-calc(21);
    text-align: left;
	}

	&__evaluate-state {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: rem-calc(5);

		@include breakpoint(large up) {
			margin-bottom: 0;
		}
	}

	&__evaluate-step {
		color: $secondary-color;
		font-size: rem-calc(24);
	}

	&__evaluate-description {
		color: $text-weak-color;
		font-size: rem-calc(12);
		line-height: 1rem;
		width: 240px;

		@include breakpoint(large up) {
			max-width: 240px;
			width: auto;
		}
  }

  .large-state-chip{
    text-align: left;
  }
}

#detalleProceso {
  position: relative;
  padding-top: 230px;

  .close-button{
    position: absolute;
    color: #fff;
    z-index: 99;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .hero_proceso{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-image: url("/img/desk_hero.png");
    height: 200px;
    background-position: center center;
    // display: grid;
    // justify-items: center;
    // align-items: center;
    padding: 40px 20px;

    h2{
      color: #fff;
      font-size: 2rem;
      margin-bottom: 0;
      margin-top: 20px;
    }

    p{
      color: #fff;
      text-align: center;
    }
  }
  h2{
    font-size: 1.5rem;
    text-align: center;
    color: #f39200;
  }

  ul.estadisticas{
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 10px;
    width: 100%;
    margin-bottom: 60px;
    margin-top: 40px;

    li{
      text-align: center;

      span.label{
        display: inline-block;
        width: 100%;
        font-weight: bold;
        font-size: .8rem;
        color: #555;
      }

      span.stat{
        font-weight: 2rem;
        color: #1d71bb;
      }
    }
  }

  ul.historial{
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 60px;

    li{
      border-bottom: 1px solid #eee;
      padding-top: 20px;

      &:last-child{
        border-bottom: none;
      }
      span{
        display: inline-block;
        width: 100%;

        &.fechaevento{
          font-size: .8rem;
        }

        &.nombreevento{
          font-size: .8rem;
          color: #1d71bb;
          font-weight: bold;
        }
      }

      p.descripcion_evento{
        text-align: left;
      }
    }
  }

  p{
    text-align: center;

    a.link_pse{
      display: inline-block;
      margin-bottom: 20px;
      img{
        width: 80px;
      }
    }
  }

  .botones{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    max-width: 80%;
    margin: 20px auto;
  }
}
