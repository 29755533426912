#calculadora {
  position: fixed;
  bottom: -100px;
  right: 0px;
  z-index: 101;
  // transition: all .5s;
  opacity: 0;

  .calculadora__ventana {
    display: none;
    transition: all .2s;
  }

  @include breakpoint(medium) {
    right: 30px;
  }

  &.active {
    opacity: 1;
    bottom: 22px;
    right: 100px;

    @include breakpoint(medium) {
      right: 110px;
      bottom: 22px;
    }

    .calculadora__cta {
      position: absolute;
      bottom: 0;
      right: 15px;
      min-width: 240px;
      text-align: right;

      @include breakpoint(medium) {
        right: 0;
      }

      a {
        display: inline-block;
        color: white;
        background-color: $secondary-color;
        padding: 15px 20px;
        transition: all .2s;
        border-radius: 30px;
        box-shadow: 2px 2px 3px 1px #48484847;
        transition: all .3s;
        font-weight: 500;
        position: relative;
        padding-left: 45px;
        border: 2px solid $secondary-color;
        font-size: .8rem;
        min-width: 190px;
        text-align: center;

        @include breakpoint(medium) {
          font-size: 1rem;
        }

        i {
          position: absolute;
          font-size: 1.5rem;
          top: 8px;
          left: 15px;
        }

        &:hover {
          box-shadow: 2px 2px 13px 5px #4c4c4c63;
          border: 2px solid $secondary-color;
        }
      }

    }
  }

  &.opened {
    left: 0px;
    top: 0px;
    right: 0px;


    @include breakpoint(medium) {
      left: 30px;
      top: 20%;
      right: 30px;
    }

    .calculadora__cta {
      position: absolute;
      bottom: 0;
      right: 115px;

      @include breakpoint(medium) {
        right: 80px;
      }
    }

    .calculadora__ventana {
      display: block;
      position: absolute;
      bottom: 60px;
      width: 100%;
      top: 0;
      background-color: #f5f8f9;
      border-radius: 5px;
      box-shadow: 2px 2px 13px 5px #4c4c4c63;
      transition: all .2s;

      @include breakpoint(medium) {
        bottom: 70px;
        right: 0;
        max-width: 800px;
      }


      &-container {
        position: absolute;
        padding: 15px;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow-y: auto;
        overflow-x: none;

        h2{
          font-size: 2rem;
          color: $secondary-color;
        }

        p.intro {
          margin-bottom: 60px;
        }

        .tabs {
          width: 100%;

          ul {
            display: block;
            width: 100%;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              display: inline-block;

              a {
                background-color: rgba($color: #fff, $alpha: 0.3);
                padding: 10px 20px 13px 20px;
                color: $secondary-color;
                border-top: 2px solid rgba($color: #fff, $alpha: 0.3);
                transition: all .2s;

                &.active,
                &:hover {
                  background-color: rgba($color: #fff, $alpha: 1);
                  border-top: 2px solid $secondary-color;
                }
              }
            }
          }
        }

        .calculadoras {
          background-color: white;
          padding: 20px 20px;
          margin-top: 10px;

          p.disclaimer {
            font-size: 14px;
            margin-top: 20px;
          }

          .esta_calculadora {
            display: none;

            &.active {
              display: block;

              @include breakpoint(large) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 40px;
              }
            }

            h5 {
              color: $secondary-color;
            }

            label{
              font-weight: bold;
            }

            .input {
              padding-top: 20px;
            }

            .result {
              padding-top: 20px;

              table {
                width: 100%;
                border: 1px solid #eee;

                tr {
                  &:nth-child(odd) {
                    background-color: #f5f8f9;
                  }

                  td {
                    padding: 10px;
                    vertical-align: top;
                  }

                  td:first-child {
                    font-weight: 800;
                    font-size: 0.75rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
