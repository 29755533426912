/**
 * Loader
 *
 * @version 1.0
 */

.loader, .main-loader__animation {
  border-radius: 50%;
  height: 20px;
  width: 20px;
  &:after {
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }
  &.loader--medium {
    height: 40px;
    width: 40px;
    &:after {
      height: 40px;
      width: 40px;
    }
  }
  &.loader--large {
    height: 60px;
    width: 60px;
    &:after {
      height: 60px;
      width: 60px;
    }
  }
}

.loader {
  animation: active-spinner 1.1s infinite linear;
  border-bottom: .1rem solid $white;
  border-left: .1rem solid $primary-color;
  border-right: .1rem solid $white;
  border-top: .1rem solid $white;
  font-size: 10px;
  margin: 0 auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
}

@keyframes active-spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

body {
  &.is-loading {
    overflow: hidden;
  }
  .main-loader {
    z-index: 999999;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #FFFFFF;
  }
  .main-loader__animation {
    position: relative;
    top: 180px;
    width: 64px;
    height: 64px;
    animation: active-spinner 1.1s infinite linear;
    border-bottom: .1rem solid $white;
    border-left: .1rem solid $primary-color;
    border-right: .1rem solid $white;
    border-top: .1rem solid $white;
    font-size: 10px;
    margin: 0 auto;
    text-indent: -9999em;
    transform: translateZ(0);
    &:after {
      height: 64px;
      width: 64px;
    }
  }
}