.header-disclaimer{
  background-color: rgb(212, 67, 0);
  color: white;
  padding: 5px;
  font-size: rem-calc(14);
  display: none;
  p{
    margin-bottom: 0;
    text-align: center;
  }
}

.header {
	background-color: white;
	border-bottom: 1px solid white;
	padding: 10px;
	position: relative;
  z-index: 4;
  box-shadow: 1px 1px 2px 0px #44444412;
  position: relative;

	@include breakpoint(920 up) {
		padding-bottom: 0;
		padding-top: 0;
	}

	&__container {
		align-items: center;
		display: grid;
		height: 40px;
		position: relative;

		@include breakpoint(920 up) {
			height: 70px;
      padding-top: 20px;
      grid-template-columns: 280px auto;
      justify-items: center;
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
		}

		@include breakpoint(xlarge up) {
			max-width: 1200px;
		}

		@include breakpoint(1600) {
			max-width: 1300px;
		}
	}

	.header-logo {
		align-self: start;

    @include breakpoint(920 up) {
			justify-self: center;
		}

		@include breakpoint(large up) {
      align-self: auto;
			display: block;
		}

		img {
      max-width: 128px;
      // filter: contrast(0) brightness(2);
		}
	}

	.actions {
		align-self: center;
		font-size: 2rem;
		grid-column: 11/13;
		justify-self: end;
		line-height: 2rem;

		a {
			color: $secondary-color;
			font-size: rem-calc(24);
		}

		.mdi-close {
			display: none;
		}

		&.active {
			.mdi-close {
				display: inline-block;
			}

			.mdi-menu {
				display: none;
			}
		}
	}

	.header-search {

    margin-left: 30px;
    position: relative;
		@include breakpoint(920 up) {
      margin-left: 0px;
			display: grid;
		}

		@include breakpoint(920 down) {
			// border-left: 1px solid $pale-grey-three;
			margin-left: 30px;
			padding-left: .7rem;
			padding-right: 2rem;
      display: flex;
      position: relative;

			.header-search__close {
				display: none;
			}

			.header-search__icon {
				display: block;
        margin-right: 5px;
			}

			.button {
				display: none;
			}

			&__active {
				border-left: 0;
				grid-column: 1 / -1;
				margin-left: 0;
				padding-left: 0;
        padding-top: 0;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

				.header-search__close {
					display: block;
					z-index: 10;
        }

        #search-button{
          background-color: transparent !important;
          border: none !important;
          top: 0 !important;
          left: -30px !important;
        }
			}
		}

		&__icon,
		&__close {
			display: none;
			color: $secondary-color;
			cursor: pointer;
			font-size: rem-calc(20);
			line-height: 40px;
		}

		&__close {
			font-size: 1rem;
			position: absolute;
			right: 0;
    }

    &__icon {
      padding-top: 3px;
    }

    #search-button{
      position: absolute;
      top: -20px;
      padding: 0px 25px;
      background-color: #f39200;
      color: white;
      border-radius: 3px;
    }
	}

	.header-search__box {
		align-items: center;
		display: flex !important;
		max-width: 650px;

    @include breakpoint(920 up) {
			justify-self: start;
      width: 520px;
		}

		input {
			border-radius: 3px;
			padding-left: 1rem;
			margin: 0;
			height: 40px;
			border-bottom-width: 0;
      width: 100%;
      border: 1px solid #ccc;

			@include breakpoint(920 down) {
				background-color: white;
        width: 100%;
        border: none;
      }

      &[name="search_car"]{
        width: 100%;
        border-radius: 20px;
        margin-right: 10px;
        box-shadow: 1px 1px 1px #eee;
      }
		}

		button {
			display: block;
      margin-bottom: 0;
      margin-left: -4px;
			outline: none;
			padding: .72rem 1.3rem;
      position: relative;
      color: white;
      border-radius: 20px;
      transition: all .2s;


      &:hover{
        background-color:#ffa928;
      }


			i {
				font-size: 1rem;
				left: .6rem;
				position: absolute;
				top: .7rem;
			}

			span {
				padding-left: .7rem;
			}
		}
	}

	.header-nav {
		align-self: center;

    &-fav {
      i {
        color: red;
      }
    }
	}

	.header-user-menu {
		display: flex;
		flex-direction: column;
		font-size: .9rem;
		line-height: 2rem;
		@extend %u-normalize-list;

		li {
			cursor: pointer;

			&:hover {
				color: $secondary-color;
			}

			a {
				color: inherit;
				font-size: .9rem;
			}
		}
	}

	.header-nav-menu {
		@extend %u-normalize-list;
		display: flex;
		justify-content: flex-end;

		> li {
			a {
				color: $secondary-color;
				cursor: pointer;
				font-size: rem-calc(12);
				font-weight: bold;
				margin: 0 .5rem;
				padding: rem-calc(27) 0;

				&:hover {
					color: $secondary-color;
				}
			}
		}
	}

	.header-user-info {
		cursor: pointer;
		display: none;
		font-size: .9rem;
		line-height: 1.7rem;
		margin-left: 1rem;
		padding-right: .85rem;
    position: relative;
    color: $secondary-color;

		@include breakpoint(large up) {
			display: block;
		}

		&::after {
			border-style: solid;
			border-width: .1em .1em 0 0;
			content: '';
			display: inline-block;
			height: .5em;
			position: absolute;
			right: 0;
			top: .55rem;
			transform: rotate(135deg);
			vertical-align: top;
			width: .5em;
		}
	}
}
