.custom-icon-heart {
  background-color: $pale-grey-two;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba($pale-grey, .4);
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;

  i {
    color: $pale-grey;
    font-size: rem-calc(20);
  }

  i:last-child {
    color: $red;
    display: none;
  }
}

.custom-icon-heart--active {
  i:first-child {
    display: none;
  }

  i:last-child {
    display: block;
  }
}

.custom-icon-360 {
  background-color: $pale-grey-two;
  border-radius: 2px;
  box-shadow: 0 0 4px rgba($pale-grey, .4);
  cursor: pointer;
  height: 28px;
  line-height: 28px;
  text-align: center;
  width: 70px;
}
