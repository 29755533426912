//
// ONLY MENU PARTIAL
//

@include breakpoint(small up) {
  body {
    section.mobile_menu {
      display: block;
      width: 100%;
      position: absolute;
      background-color: #fff;
      transition: all 0.2s ease;
      z-index: 30;
      top: -500px;

      &.enabled {
        top: 56px;
        box-shadow: 0px 5px 11px 1px #44444424;
      }
    }
  }
}

@include breakpoint(921 up) {
  body {
    section.mobile_menu {
      display: none;
    }
  }
}

@include breakpoint(medium up) {
  body {
  }
}

@include breakpoint(large up) {
  body {
  }
}
