.contact-content {
	// background-color: $pale-grey-two;
	// background-color: $pale-grey-one;
	min-height: 250px;
	padding: 2rem 1rem;

	@include breakpoint(large up) {
		padding: 6rem 0;
  }

  .ctas_block{
    text-align: center;

    .login__no-account{
      text-align: center;
    }

    .button{
      color: white;
    }
  }

  .login__social{
    max-width: 600px;
    margin: 0 auto;

    a{
      &.login__social-twitter{
        margin-right: 10px;
      }
    }
  }
}

.contact-form {
	margin: 0 auto;
  max-width: 500px;

  h3{
    color: $xibilla-blue;
    text-align: center;
    font-size: rem-calc(20);
    line-height: rem-calc(28);
    margin-bottom: 30px;
  }

  h2{
    color: $xibilla-blue;
    text-align: left;
    font-size: rem-calc(25);
    margin-top: 50px;
    margin-bottom: 30px;
    &:first-child{
      margin-top: 0;
    }
  }

  p{
    text-align: center;
    margin-bottom: 30px;

    &.solicitar_codigo{
      margin-top: 40px;
      font-size: rem-calc(14);
      a{
        color: $xibilla-blue;
      }
    }

    &.left{
      text-align: left;
      margin-bottom: 30px;
    }
  }

	select,
	input,
	textarea {
    padding: 25px 10px;
		background-color: $pale-grey-two;

		&:focus {
			background-color: $pale-grey-two;
		}
  }

  select{
    padding: 0;
    height: 50px;
    padding: 0 35px 0 10px;
  }

	&__terms {
		display: flex;
		margin: 2rem 0;

		a {
			color: #1d71bb;
		}

		.is-invalid-label a, &.error {
      color: #cc4b37;

      label{
        color: #cc4b37;

        a{
          color: #cc4b37;
        }
      }
		}

		input {
			margin-top: .2rem;
		}
	}

	&__button {
    text-align: right;
	}

	div.error {
    input, select{
      margin-bottom: 5px;
    }
    .form-error{
      display: inline-block;
      margin-top: 0;
      font-weight: 400;
    }
	}
}
