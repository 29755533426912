.xibilla-button{
  display: inline-block;
  background-color: $cool-orange;
  color: white;
  padding: 10px 15px;
  border-radius: 3px;
  font-weight: bold;
  font-size: rem-calc(14);
  transition: all .2s;

  &:hover{
    color: white;
    background-color: lighten($cool-orange, 10%);
  }
}
