.reset-content {
	// background-color: $pale-grey-two;
	// background-color: $pale-grey-one;
	min-height: 250px;
	padding: 2rem 1rem;

	@include breakpoint(large up) {
		padding: 3rem 0;
  }

  .successMessage{
    max-width: 500px;
    margin: 0 auto;
    display: none;

    h2{
      font-size: 1.8rem;
    }

    .button{
      display: inline-block;
      margin-top: 30px;
    }
  }
}

.reset-form {
	&__success-message {
		display: none;
		color: green;
		margin-bottom: 10px;
		font-size: 14px;
	}

	&__error-message {
		color: red;
		display: none;
		margin-bottom: 10px;
		font-size: 14px;
		text-align: center;
	}
}

.reset-form {
	margin: 0 auto;
	max-width: 500px;

	select,
	input,
	textarea {
		background-color: $pale-grey-two;

		&:focus {
			background-color: $pale-grey-two;
		}
	}

	&__terms {
		display: flex;
		margin: 2rem 0;

		a {
			color: #1d71bb;
		}

		.is-invalid-label a {
			color: #cc4b37;
		}

		input {
			margin-top: .2rem;
		}
	}

	&__button {
		text-align: right;
	}

	div {
		.prefix_container {
			display: inline-block;
			width: 25%;
		}
		.phone_container {
			width: 70%;
			display: block;
			float: right;
		}
	}
}
