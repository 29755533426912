.account-content {
	// background-color: $pale-grey-two;
	// background-color: $pale-grey-one;
	min-height: 250px;
	padding: 2rem 1rem;

	@include breakpoint(large up) {
		padding: 3rem 0;
	}
}

.pass-form {
	&__success-message {
		display: none;
		color: green;
		margin-bottom: 10px;
	}

	&__error-message {
		color: red;
		display: none;
		margin-bottom: 10px;
	}
}

.account-form {
	margin: 0 auto;
	max-width: 500px;

	select,
	input,
	textarea {
		background-color: $pale-grey-two;

		&:focus {
			background-color: $pale-grey-two;
		}
	}

	&__terms {
		display: flex;
		margin: 2rem 0;

		a {
			color: #1d71bb;
		}

		.is-invalid-label a {
			color: #cc4b37;
		}

		input {
			margin-top: .2rem;
		}
	}

	&__button {
		text-align: right;
	}

	&__success-message {
		display: none;
		color: green;
		margin-bottom: 10px;
	}

	&__error-message {
		color: red;
		display: none;
		margin-bottom: 10px;
	}

	div {
		.prefix_container {
			display: inline-block;
			width: 25%;
		}
		.phone_container {
			width: 70%;
			display: block;
			float: right;
		}
	}
}

.sn-actions{
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-top: 20px;

  li{
    margin-bottom: 20px;

    .name{
      display: inline-block;
      width: 100px;
      width: 100%;
      margin-bottom: 5px;
    }

    .status{
      display: inline-block;
      font-size: .8rem;
      background-color: #eee;
      padding: 3px 10px;
      width: 110px;
      text-align: center;
      margin-left: 22px;

      &.connected{
        background-color: #1d71bb;
        color: #fff;
      }
    }

    a.action{
      display: inline-block;
      font-size: .8rem;
      padding: 3px 10px;
      color: #1d71bb;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
