.seller-info-hero {
	background-color: $blue-grey-dark;
	background-image: url("/img/desk_hero.png");
	background-position: 50% 70%;
	background-size: cover;
	height: 270px;
	position: relative;

	@include breakpoint(large up) {
		background-position: 50% 40%;
		height: 330px;
	}

	&__copy {
		color: $white;
		padding: 2rem 1rem 0;
		position: relative;
		z-index: 2;

		@include breakpoint(large up) {
			padding-top: 5rem;
			text-align: center;
		}

		h1 {
			font-size: rem-calc(25);

			@include breakpoint(large up) {
				font-size: rem-calc(40);
			}
		}

		p {
			color: $text-weak-color;
			line-height: 1.2rem;

			@include breakpoint(large up) {
				font-size: rem-calc(20);
			}
		}
	}

	&__steps-container {
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		z-index: 3;

		@include breakpoint(1000) {
			// bottom: 5.5rem;
		}
	}

	&__steps {
		display: grid;
		grid-template-columns: repeat(3, 1fr);

		@include breakpoint(1000) {
			justify-items: start;
			max-width: 480px;
			padding: 0;
		}
	}

	&__step {
		color: $text-weak-color;
		display: flex;
		font-size: rem-calc(12);
		justify-content: center;
		padding: 1rem 0;
		width: 100%;

		@include breakpoint(1000) {
			justify-content: flex-start;
			width: 85%;
		}

		span {
			font-size: rem-calc(24);
			line-height: 1.7rem;
			margin-right: rem-calc(5);

			@include breakpoint(1000) {
				margin-right: rem-calc(10);
			}
		}

		p {
			display: none;
			line-height: 1rem;
			margin-bottom: 0;
			width: 80px;

			@include breakpoint(1000) {
				display: block;
				width: 100px;
			}
		}
	}

	&__step--active {
		border-top: 3px solid $white;
		color: $white;
		padding-top: calc(1rem - 2px);

		p {
			display: block;
		}
	}

	&__overlay {
		background-color: $blue-grey-dark;
		bottom: 0;
		left: 0;
		opacity: .7;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

.seller-info-container {
	background-color: $white;
	box-shadow: 0 10px 40px $pale-grey-three;

	@include breakpoint(1000) {
		border: 1px solid $pale-grey-three;
		display: grid;
		grid-template-columns: 290px 1fr;
		margin: 4rem auto;
		position: relative;
    z-index: 3;
    min-height: 457px;
	}
}

.seller-info-container-error {
	background-color: $white;
	box-shadow: 0 10px 40px $pale-grey-three;

	@include breakpoint(1000) {
		border: 1px solid $pale-grey-three;
		margin: 4rem auto;
		position: relative;
		z-index: 3;
	}

	.seller-info-error {
    padding: 100px 20px;
    display: grid;

    .button{
      justify-self: start;

      @include breakpoint(1000){
        justify-self: center;
      }
    }

    .goback{
      margin-top: 50px;
      color: #1d71bb;
    }

		@include breakpoint(1000) {
			text-align: center;
		}
	}
}

.seller-info-ref {
	padding: 2rem 1rem 0;
	text-align: center;

	@include breakpoint(1000) {
		border-right: 1px solid $pale-grey-three;
		padding: 2rem 1.5rem;
	}

	img {
		width: 200px;
		margin-bottom: 30px;
	}

	h4 {
		font-size: rem-calc(24);
		margin-bottom: 0;

		@include breakpoint(1000) {
			margin-bottom: .5rem;
			text-align: left;
		}
	}

	&__list {
		@extend %u-normalize-list;
		color: $text-weak-color;
		height: 20px;
		overflow-y: hidden;
		transition: height .5s;

		@include breakpoint(1000) {
			height: auto;
			text-align: left;
		}
	}

	&__list--active {
		height: 170px;
		transition: height .5s;
	}

	&__see {
		color: $text-weak-color;
		font-size: rem-calc(12);
		margin: 1rem auto 0;
		width: 120px;

		@include breakpoint(large up) {
			margin-bottom: 1.5rem;
		}

		@include breakpoint(1000) {
			display: none;
		}

		> div:last-child {
			display: none;
		}
	}

	&__see-more,
	&__see-less {
		align-items: center;
		display: flex;
		justify-content: center;

		i {
			font-size: 1.2rem;
			padding-bottom: .1rem;
		}
	}

	&__see--active {
		> div:first-child {
			display: none;
		}

		> div:last-child {
			display: flex;
		}
  }

  li.exact-match{
    display: none;
    font-size: .8rem;
    margin-top: 15px;
  }
}

.seller-info-main {
	padding: 2rem 0;

	@include breakpoint(1000) {
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

.seller-info-vehicle,
.seller-info-data {
	margin: 0 auto;
	// max-width: 550px;
	padding: 0 1rem;
}

.seller-info-vehicle {
  &_panel{
    display: none;
  }

  .tipo-caja-selection{
    label{
      text-transform: uppercase;
      font-size: 1rem;
      color: #1d71bb;
    }

    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 20px;

      li{
        display: block;
        margin-right: 20px;
        margin-bottom: 20px;

        @include breakpoint(medium){
          float: left;
        }

        a{
          display: inline-block;
          border: 1px solid #eee;
          border-radius: 2px;
          padding: 8px 10px;
          color: #777;
          transition: all .2s;
          font-size: 1rem;
          cursor: pointer;

          i{
            color: #fff;
            transition: all .2s;
          }

          &:hover{
            border: 1px solid #1d71bb;
            color: #1d71bb;

            i{
              color: #1d71bb;
            }
          }
        }
      }
    }
  }

  .vehicle-table{
    overflow-x: auto;
    .backToTipoCaja{
      display: none;
    }

    table{
      width: 100%;
      margin-bottom: 50px;
      margin-top: 50px;
      border-collapse: collapse;
      thead{
        td{
          text-transform: uppercase;
          font-size: .8rem;
          text-align: center;
          border-bottom: 1px solid #ccc;
          padding: 12px 0;
          padding-right: 15px;

          &:first-child{
            font-weight: bold;
            text-align: left;
            padding-left: 10px;
          }

          &:last-child{
            color: #fff;
          }
        }
      }

      tbody{
        tr{
          display: none;
          &.visible{
            display: table-row;
          }
        }

        td{
          padding: 12px 0;
          font-size: .8rem;
          text-align: center;
          padding-right: 15px;
          font-weight: bold;
          border-bottom: 1px solid #eee;
          transition: all .2s;
          &:first-child{
            text-align: left;
            font-weight: normal;
            padding-left: 10px;
          }

          &:last-child{
            font-weight: normal;
            color: #ccc;
          }

          span.string{
            width: 100%;
            display: block;
          }

          span.icon{
            width: 100%;
            display: none;
            color: #1d71bb;
            height: 100%;
          }
        }

        tr:hover, tr.active{
          cursor: pointer;
          td{
            color: #1d71bb;
            // border-bottom: 1px solid #1d71bb;
          }

          span.string{
            display: none;
          }

          span.icon{
            display: block;
          }
        }
      }
    }
  }
}

.seller-info-data {
	p {
		font-size: rem-calc(14);

		&.note {
			display: none;
		}
	}

	form {
		display: none;
	}

	&__form-group {
		margin-top: 2rem;

		@include breakpoint(1000) {
			margin: 3rem auto 0;
			width: 400px;
		}

		// input[type="text"],
		// input[type="password"] {
		// 	margin-bottom: 2rem;
		// }
	}

	&__terms {
    width: 100%;
    padding-bottom: rem-calc(20);
    padding-left: 20px;
    position: relative;

    input[type="checkbox"]{
      position: absolute;
      left: 0;
      top: 3px;
    }

		.is-invalid-label {
			label {
				font-size: 16;
				font-weight: 400;
			}
			a {
				color: #cc4b37;
			}
		}

		a {
			color: #1d71bb;
		}

		input {
			margin-top: .2rem;
		}
	}
}

.seller-info-state {
	border-top: 1px solid $pale-grey-three;
	display: grid;
	grid-template-rows: repeat(5, minmax(100px, auto));

	@include breakpoint(large up) {
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: 200px 130px;
		margin-top: -2rem;
	}

	@include breakpoint(1000) {
		border: 0;
	}
}

.seller-info-state-item {
	border-bottom: 1px solid $pale-grey-three;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 1rem;

	@include breakpoint(large up) {
		justify-content: flex-start;
		padding-top: 2.2rem;
	}
}

.seller-info-qualify {
	grid-column: 1 / -1;
	position: relative;

	@include breakpoint(large up) {
		padding-top: 5rem;

		&:nth-child(n + 2) {
			border-left: 1px solid $pale-grey-three;
		}
	}

	&__toggle {
		position: absolute;
		right: 1rem;
		top: 2rem;

		@include breakpoint(large up) {
			bottom: 0;
			left: 0;
			right: 0;
			text-align: center;
			top: auto;
		}

		i {
			color: $pale-grey;
			font-size: 1.5rem;

			&:first-child {
				display: none;
			}

			@include breakpoint(large up) {
				cursor: pointer;

				&:first-child {
					display: inline-block;
				}

				&:last-child {
					display: none;
				}
			}
		}
	}

	&__toggle--open {
		i {
			&:first-child {
				display: block;
			}

			&:last-child {
				display: none;
			}

			@include breakpoint(large up) {
				&:first-child {
					display: none;
				}

				&:last-child {
					display: block;
				}
			}
		}
	}

	&__wrap {
		align-items: center;
		display: flex;
		line-height: 1rem;

		i {
			font-size: 2rem;
			margin-right: 1rem;
		}

		label {
			color: $text-weak-color;
			font-size: rem-calc(12);
			line-height: 1.4rem;
		}

		p {
			font-size: rem-calc(18);
			line-height: 1rem;
			margin-bottom: 0;
			&.invalid {
				color: red;
			}
		}

		@include breakpoint(large up) {
			i {
				position: absolute;
				right: 1rem;
				top: 3rem;
			}
		}
	}
}

.seller-info-outside {
	grid-row: 1;
	@include breakpoint(large up) {
		grid-column: 1;
		grid-row: 1;
	}
}

.seller-info-inside {
	grid-row: 2;

	@include breakpoint(large up) {
		grid-column: 2;
		grid-row: 1;
	}
}

.seller-info-mechanic {
	grid-row: 3;

	@include breakpoint(large up) {
		grid-column: 3;
		grid-row: 1;
	}
}

.seller-info-tires {
	padding-top: 1.5rem;

	@include breakpoint(large up) {
		grid-column: span 2;
		padding-top: 1.6rem;
	}

	p {
		color: $red;
		margin-bottom: 0;
	}
}

.seller-info-kms {
	@include breakpoint(large up) {
		border-left: 1px solid $pale-grey-three;
	}

	&.invalid {
		label {
			color: red;
		}

		input {
			border-bottom: 1px solid red;
		}
	}

	label {
		margin-bottom: .5rem;
	}

	> span {
		color: red;
		font-size: 0.7rem;
		margin-top: 5px;
		display: none;
	}

	&__wrapper {
		position: relative;

		span {
			bottom: .5rem;
			color: $text-weak-color;
			font-size: rem-calc(12);
			position: absolute;
			right: 15px;
		}

		input {
			font-size: rem-calc(24);
			margin-bottom: 0;
		}
	}
}

.seller-info-states-list {
	grid-column: 1;
	grid-row: auto;
	position: relative;
	z-index: 4;

	@include breakpoint(large up) {
		grid-column: 2;
		overflow: hidden;
	}

	ul {
		@extend %u-normalize-list;
		background-color: $cool-grey;
		box-shadow: 0 4px 40px $pale-grey;
		color: $white;
		height: 0;
		overflow: hidden;
		transition: .2s;

		@include breakpoint(large up) {
			bottom: 0;
			box-shadow: none;
			height: 200px;
			left: 0;
			opacity: 1;
			padding-top: rem-calc(5);
			position: absolute;
			right: 0;
			top: 200px;
		}
	}

	li {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-size: rem-calc(12);
		padding: .4rem 1rem;

		@include breakpoint(large up) {
			padding: .2rem 1rem;
		}

		i {
			font-size: 1.4rem;
			margin-right: .5rem;
		}
	}
}

.seller-info-states-new {
	i,
	p {
		color: $cool-blue;
	}
}

.seller-info-states-good {
	i,
	p {
		color: $green;
	}
}

.seller-info-states-maintenance {
	i,
	p {
		color: $yellow;
	}
}

.seller-info-states-broken {
	i,
	p {
		color: $red;
	}
}

.seller-info-states-list--open {
	ul {
		animation-duration: .5s;
		animation-fill-mode: forwards;
		animation-name: states-list-open-small;

		@include breakpoint(large up) {
			animation-name: states-list-open-large;
		}

		.mdi-chevron-up {
			display: none;
		}

		.mdi-chevron-down {
			display: block;
		}
	}
}

.seller-info-states-list--close {
	ul {
		animation-duration: .5s;
		animation-fill-mode: forwards;
		animation-name: states-list-close-small;

		@include breakpoint(large up) {
			animation-name: states-list-close-large;
		}
	}
}

@keyframes states-list-open-small {
	0% {
		height: 0;
	}

	100% {
		height: 200px;
	}
}

@keyframes states-list-close-small {
	0% {
		height: 200px;
	}

	100% {
		height: 0;
	}
}

@keyframes states-list-open-large {
	0% {
		top: 200px;
	}

	100% {
		top: 0;
	}
}

@keyframes states-list-close-large {
	0% {
		top: 0;
	}

	100% {
		top: 300px;
	}
}

.seller-info-states-list--open-outside {
	grid-row: 2;

	@include breakpoint(large up) {
		grid-column: 1;
		grid-row: 1;
	}
}

.seller-info-states-list--open-inside {
	grid-row: 3;

	@include breakpoint(large up) {
		grid-column: 2;
		grid-row: 1;
	}
}

.seller-info-states-list--open-mechanic {
	grid-row: 4;

	@include breakpoint(large up) {
		grid-column: 3;
		grid-row: 1;
	}
}

.seller-info-thanks {
	padding: 0 3rem;

	h4 {
		font-size: 1.3rem;
	}

	p {
		color: $text-weak-color;
		font-size: rem-calc(15);
	}

	p:last-child {
		text-align: center;
	}
}

.seller-info-loading{
  p{
    color: #ccc;
    padding-left: 30px;
  }
}

.seller-info-buttons {
	margin-right: 1rem;
	margin-top: 2rem;
	text-align: right;

	@include breakpoint(1000) {
		margin-right: 3rem;
	}

	a:last-child {
		margin-left: 1rem;
	}

	#sellerContinue {
		width: 130px !important;
		height: 45px;

		img {
			display: none;
			margin-top: -5px;
		}
  }

  &.picker-corrector{
    margin-top: -35px;
  }
}

.seller-slider {
	.slider {
		background-color: $pale-grey-three;
	}

	.slider,
	.slider-fill {
		height: .2rem;
	}

	.slider-fill {
		background: linear-gradient(to right, $red);
	}

	.slider-handle {
		background-color: $white;
		border: 2px solid $red;
		border-radius: 50%;
		height: 14px;
		width: 14px;
	}
}
