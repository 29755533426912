div.crawlerCTA{
  position: fixed;
  bottom: 10px;
  left: 0px;
  z-index: 101;
  display: none;

  &.active{
    display: block;
  }

  a{
    background: linear-gradient(135deg,rgb(138, 5, 5) 0%,rgb(206, 64, 64) 100%);
    padding: 15px 20px;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    box-shadow: 1px 0px 40px -10px rgba(0, 0, 0, 0.62);
    transition: all .2s;

    &:hover{
      box-shadow: 1px 0px 40px -10px rgb(36, 36, 36);
    }
  }
}

#crawler_modal{
  .crawlerContent{
    .actions{
      padding: 20px 0;

      a{
        background: linear-gradient(135deg,rgb(138, 5, 5) 0%,rgb(206, 64, 64) 100%);
        padding: 10px 20px;
        color: white;
        font-weight: bold;
        border-radius: 3px;
        box-shadow: 1px 0px 40px -10px rgba(0, 0, 0, 0.62);
        transition: all .2s;

        &:hover{
          box-shadow: 1px 0px 40px -10px rgb(36, 36, 36);
        }
      }
    }

  }
}
