.seller-data{

  .seller-data-container{
    background-color: #f5f8f9;

    @include breakpoint(1000){
      background-color: white;
    }
  }

    a.cta{
      display: inline-block;
      background-color: #f39200;
      color: white;
      padding: 13px 20px;
      border-radius: 3px;
      font-weight: bold;
      &:hover{
        background-color: #ffa928;
      }
    }

    a.back{
      display: inline-block;
      color: #1d71bb;
      padding: 0;
      border-radius: 3px;
      font-weight: bold;
      font-size: rem-calc(12);
      position: relative;
      padding-left: 20px;
      i{
        position: absolute;
        left: 0;
        top: -7px;
        font-size: rem-calc(22);
      }
    }


    .form_row{

        [name="placa"]{
            text-transform: uppercase;
        }

        .error_required.active{
          visibility: visible;
          margin-top: -20px;
          display: block;
          margin-bottom: 30px;

          @include breakpoint(1000) {
            margin-top: 10px;
          }
        }


    }

    .form_checks{
      label.error{
        color: red;
      }

      // TODO: AJUSTAR PARA DESKTOP
      .cta{
        margin-left: calc(50% - 60px);
        margin-top: 30px;

        @include breakpoint(1000) {
          margin-left: 0;
        }
      }
    }

    .vender-loading{
      h2{
        text-align: left;
        color: #62656f;
        font-size: rem-calc(16);
      }
    }

    .no_encontrado{
      h2{
        color: #1d71bb;
        font-size: rem-calc(22);
        margin-bottom: 50px;
      }

      p{
        margin-bottom: 50px;
      }

      // TODO: AJUSTAR PARA DESKTOP
      .cta{
        margin-left: calc(50% - 98px);
      }
    }

    .seller-info-ref{
      border-bottom: 1px solid #eee;
      margin-bottom: 40px;
      padding-bottom: 20px;
      background-color: white;
      border: 1px solid #eee;
      border-radius: 3px;
      box-shadow: 3px 3px 5px 2px #bfbfbf3b;


      @include breakpoint(1000){
        border-bottom: 0;
        text-align: left;
        margin-bottom: 0;
        padding: 20px;
        text-align: center;
        box-shadow: none;
        background-color: white;
        border-radius: 0;
        border-top: none;
      }


      h4{
        margin-bottom: 20px;
        color: #1d71bb;
        margin-bottom: 0px;

        @include breakpoint(1000){
          margin-bottom: 10px;
        }
      }

      ul{
        list-style: none;
        margin: 0;
        padding: 0;

        li{
          text-align: center;
          margin-bottom: 5px;

          .label{
            font-weight: bold;
            font-size: rem-calc(12);
          }

          @include breakpoint(1000){
            text-align: left;
            margin-bottom: 10px;
          }
        }
      }
    }

    .seleccionar_caja, .seleccionar_variante{
      text-align: center;

      @include breakpoint(1000){
        text-align: left;
        padding: 40px;
      }

      p{
        color: #1d71bb;
        font-size: 0.9375rem;
        margin-bottom: 2rem;
        text-align: center;
        font-weight: bold;

        @include breakpoint(1000){
          text-align: left;
          color: #696969;
        }
      }

      label{
        text-transform: uppercase;
        font-size: 1rem;
        color: #1d71bb;

        @include breakpoint(1000){
          text-align: left;
        }
      }

      ul.tipo_caja{
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 20px;

        li{
          display: inline-block;
          margin-right: 0;
          margin-bottom: 20px;
          margin-right: 20px;

          &:last-child{
            margin-right: 0;
          }

          @include breakpoint(medium){
            float: left;
          }

          a{
            display: inline-block;
            border: 1px solid #bbb;
            border-radius: 2px;
            padding: 8px 10px;
            color: #888;
            transition: all .2s;
            font-size: 1rem;
            cursor: pointer;
            width: 150px;
            text-align: center;

            i{
              color: #fff;
              transition: all .2s;
            }

            &:hover{
              border: 1px solid #f39200;
              color: #f39200;

              i{
                color: white;
              }
            }
          }
        }
      }

      ul.lista_opciones{
        list-style: none;
        margin: 0;
        padding: 0;
        margin-bottom: 40px;
        margin-top: 40px;

        @include breakpoint(1000){
          display: grid;
          grid-template-columns: 1fr;
          column-gap: 20px;
        }

        li{
          border-radius: 3px;
          box-shadow: 3px 3px 5px 2px #bfbfbf3b;
          width: 100%;
          margin-bottom: 20px;
          border: 1px solid rgb(199, 199, 199);
          padding: 20px;
          text-align: left;
          transition: all .2s;
          position: relative;
          padding-bottom: 60px;
          background-color: white;

          &:hover{
            @include breakpoint(1000) {
              box-shadow: 1px 0px 6px 3px #bfbfbf3b;
              border: 1px solid rgb(168, 168, 168);
              color: #1d71bb;
            }
          }

          .this_field{
            font-size: rem-calc(12);
            display: grid;
            display: inline-block;
            width: 120px;

            .label{
              font-weight: bold;
              display: inline-block;
              width: 100%;
            }

            .direccion_chekar{
              color: #333;
            }

            &.modelo{
              width:100%;
              margin-bottom: 10px;
            }
          }

          .action{
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 20px 20px;
            padding-top: 20px;
            width: 100%;
            text-align: left;

            a{
              font-weight: bold;
              font-size: rem-calc(12);
              display: inline-block;
              border: 1px solid #ffa928;
              padding: 5px 10px;
              transition: all .2s;

              &:hover{
                background-color: #ffa928;
                color: white;
              }
            }
          }
        }
      }

      table{
        width: 100%;
        margin-bottom: 50px;
        margin-top: 50px;
        border-collapse: collapse;
        display: none;
        thead{
          td{
            text-transform: uppercase;
            font-size: .8rem;
            text-align: center;
            border-bottom: 1px solid #ccc;
            padding: 12px 0;
            padding-right: 15px;

            &:first-child{
              font-weight: bold;
              text-align: left;
              padding-left: 10px;
            }

            &:last-child{
              color: #fff;
            }
          }
        }

        tbody{
          tr{
            display: none;
            &.visible{
              display: table-row;
            }
          }

          td{
            padding: 12px 0;
            font-size: .8rem;
            text-align: center;
            padding-right: 15px;
            font-weight: bold;
            border-bottom: 1px solid #eee;
            transition: all .2s;
            &:first-child{
              text-align: left;
              font-weight: normal;
              padding-left: 10px;
            }

            &:last-child{
              font-weight: normal;
              color: #f39200;
            }

            span.string{
              width: 100%;
              display: block;
            }

            span.icon{
              width: 100%;
              display: none;
              color: #f39200;
              height: 100%;
            }
          }

          tr:hover, tr.active{
            cursor: pointer;
            td{
              color: #f39200;
              // border-bottom: 1px solid #1d71bb;
            }

            span.string{
              display: none;
            }

            span.icon{
              display: block;
            }
          }
        }
      }
    }

    .declaracion{
      padding-bottom: 20px;

      p{
        color: #1d71bb;
        font-size: 0.9375rem;
        margin-bottom: 2rem;
        text-align: center;
        font-weight: bold;

        @include breakpoint(1000){
          text-align: left;
          padding: 20px;
          margin-bottom: 0;
          color: #696969;
        }

        &.programacion{
          margin-bottom: 10px;

          @include breakpoint(1000){
            text-align: left;
            padding: 20px;
            padding-bottom: 0;
            margin-bottom: 0;
            color: #696969;
          }
        }
      }

      span.descripcion_seccion{
        font-size: rem-calc(14);
        color: #696969;
        display: inline-block;
        text-align: center;
        margin-bottom: 2rem;

        @include breakpoint(1000){
          text-align: left;
          padding: 20px;
          font-size: 1rem;
          padding-bottom: 0;
        }
      }

      .fields{
        margin-bottom: 40px;
        background-color: white;
        padding: 20px;
        border: 1px solid rgb(199, 199, 199);
        box-shadow: 3px 3px 5px 2px #bfbfbf3b;

        @include breakpoint(1000){
          border: none;
          border-top: 1px solid #eee;
          display: grid;
          grid-template-columns: 1fr 1fr;
          margin-bottom: 40px;
          padding: 0;
          box-shadow: none;
        }

        .this_field{
          position: relative;
          margin-bottom: 10px;

          @include breakpoint(1000){
            border-right: 1px solid #eee;
            border-bottom: 1px solid #eee;
            padding: 20px;
            margin-bottom: 0;
          }

          i.error{
            opacity: 0;
            transition: all .2s;
            font-size: rem-calc(12);
            position: absolute;
            bottom: -5px;
            left: 0;
            color: red;

            @include breakpoint(1000){
              left: 20px;
              bottom: 10px;
            }
          }

          &.error{
            input, select{
              border-color: red;
            }

            .error{
              opacity: 1;
            }
          }

          &:last-child{
            i.error{
              // bottom: -20px;
            }
          }

          .result_icon{
            position: absolute;
            display: block;
            right: 45px;
            top: 32px;

            @include breakpoint(1000){
              right: 55px;
              top: 52px;
            }

            i{
              font-size: rem-calc(22);
            }

            i.mdi-trophy{
              color:rgb(60, 136, 44);
            }

            i.mdi-flag{
              color:rgb(148, 184, 65);
            }

            i.mdi-white-balance-iridescent{
              color:rgb(247, 201, 53);
            }

            i.mdi-radiator{
              color: red;
            }
          }

          .kms{
            position: absolute;
            display: block;
            text-align: center;
            top: 38px;
            right: 15px;
            background-color: rgb(60, 136, 44);
            color: white;
            border-radius: 2px;
            font-size: rem-calc(12);
            padding: 2px 8px;

            @include breakpoint(1000){
              top: 58px;
              right: 35px;
            }
          }

          .label{
            font-weight: bold;
            font-size: rem-calc(12);
            color: #1d71bb;

            @include breakpoint(1000){

            }
          }

          .direccion_chekar{
            color: #333;
            font-weight: 700;
            text-align: left;
            width: 100%;
            margin-bottom: 20px;
            margin-top: 20px;
          }

          select{
            border: 1px solid #eee;
            padding: 10px 10px;
            padding-right: 30px;
            height: 48px;

            @include breakpoint(1000){
              border: 1px solid rgb(180, 180, 180);
            }
          }

          input{
            border: 1px solid #eee;
            height: 48px;
            padding: 10px;

            @include breakpoint(1000){
              border: 1px solid rgb(180, 180, 180);
            }
          }
        }
      }

      .actions{
        text-align: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        @include breakpoint(1000){
          padding: 0 20px;
        }

        .back{
          justify-self: start;
        }

        .cta{
          justify-self: end;
        }
      }
    }
}

.seller-data-container.identificado{
  @include breakpoint(1000){
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 0;
  }

}
